import * as React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import useCommonStyles from '../../assets/style/commonStyle';
import { 
  CircularProgress
} from '@material-ui/core';
import Helper from "../../lib/helper";

const Section = (props) => {
  const commonClasses = useCommonStyles();

  return(<div className={commonClasses.componentSectionContainer} style={props.sectionStyle}>
    <fieldset className={clsx(commonClasses.componentSection, props.sectionClassName) }>
      {/* Use -webkit-fit-content to fix Safari not able to display the fieldset top border */}
      <legend style={{width: '-webkit-fit-content'}}>{props.label}</legend>
      {props.children}
      {
        Helper.isNotNullAndUndefined(props.toolbar) && !props.busy &&
        <div className={commonClasses.componentSectionToolbar}>
          {props.toolbar}
        </div>
      }
    </fieldset>
    {
      props.busy &&
      <div className={commonClasses.componentSectionBackdrop}>
        <CircularProgress size={50} />
      </div>
    }
  </div>);
}

Section.propTypes = {
  label: PropTypes.string.isRequired,
  sectionStyle: PropTypes.object,
  sectionClassName: PropTypes.string,
  children: PropTypes.node,
  toolbar: PropTypes.node,
  busy: PropTypes.bool
}

Section.defaultProps = {
  busy: false
}

export default Section;
