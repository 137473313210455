import * as React from "react";
import PropTypes from "prop-types";
import { Box } from '@material-ui/core';
// import clsx from 'clsx';
import useCommonStyles from '../../assets/style/commonStyle';

const TabPanel = (props) => {
  const { children, selectedTab, index, ...other } = props;
  const commonClasses = useCommonStyles();

  return(
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {selectedTab === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  selectedTab: PropTypes.any.isRequired,
}


export default TabPanel;
