import * as React from "react";
import { graphql } from "gatsby";
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { 
  Paper, Tabs, Tab, Divider, Typography, 
  FormGroup, FormControlLabel, Switch, TextField,
  IconButton, Tooltip, Zoom, Snackbar,
  Dialog, DialogTitle, DialogContent,
  DialogActions, Slide, Button, Fab,
  Accordion, AccordionDetails, AccordionSummary,
  List, ListItem, ListItemIcon, ListItemText, ListSubheader,
  InputAdornment, Checkbox
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Autocomplete } from "@material-ui/lab";
import * as MUIIcons from '@material-ui/icons';
import MuiMarkdown from 'mui-markdown';
import SwipeableViews from 'react-swipeable-views';
import Layout from "../../components/layout";
import TabPanel from "../../components/TabPanel";
import Section from "../../components/Section";
import DropDownList from "../../components/DropDownList";
import FieldValue from "../../components/FieldValue";
import Icon from "../../components/Icon";
import Toolbar from "../../components/Toolbar";
import HelpScreen from "../../components/HelpScreen";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/blue-light.css';
import '@inovua/reactdatagrid-community/theme/blue-dark.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import '@inovua/reactdatagrid-community/theme/green-dark.css';

import clsx from 'clsx';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import useCommonStyles from '../../assets/style/commonStyle';
import { isMobile, osName } from 'react-device-detect';
import * as systemActions from '../../redux/actions/systemActions';
import * as loggingActions from '../../redux/actions/loggingActions';
import Helper from '../../lib/helper';
import UIHelper from "../../lib/uiHelper";
import packageJson from '../../../package.json';

import Api from "../../lib/api";


const gridStyle = { minHeight: 'calc(100vh - 210px)' };

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const loginPersistencyValidationInitState = {
  heartbeat_duration: {error: false, touched: false, message: ''},
  heartbeat_allowance: {error: false, touched: false, message: ''},
  idle_timeout: {error: false, touched: false, message: ''}
};

const loginPersistencySectionFields = [
  {field: 'heartbeat_duration', required: true, regExp: '', type: 'string', label: 'loginPersistencySection.field.heartbeat_duration'},
  {field: 'heartbeat_allowance', required: true, regExp: '', type: 'string', label: 'loginPersistencySection.field.heartbeat_allowance'},
  {field: 'idle_timeout', required: true, regExp: '', type: 'string', label: 'loginPersistencySection.field.idle_timeout'}
];

const loginSecurityValidationInitState = {
  user_signup_role: {error: false, touched: false, message: ''},
}

const loginSecuritySectionFields = [
  {field: 'user_signup_role', required: true, regExp: '', type: 'string', label: 'loginSecuritySection.field.user_signup_role'}
];

const userMgtValidationInitState = { }

const userMgtSectionFields = [];

const systemLoggingValidationInitState = {
  max_log_cap: {error: false, touched: false, message: ''},
  sync_batchsize: {error: false, touched: false, message: ''},
  sync_interval: {error: false, touched: false, message: ''},
  retry_interval: {error: false, touched: false, message: ''}
}

const systemLoggingSectionFields = [
  {field: 'max_log_cap', required: true, regExp: '', type: 'string', label: 'systemLoggingSection.field.max_log_cap'},
  {field: 'sync_batchsize', required: true, regExp: '', type: 'string', label: 'systemLoggingSection.field.sync_batchsize'},
  {field: 'sync_interval', required: true, regExp: '', type: 'string', label: 'systemLoggingSection.field.sync_interval'},
  {field: 'retry_interval', required: true, regExp: '', type: 'string', label: 'systemLoggingSection.field.retry_interval'}
];

const iconOptions = Object.keys(MUIIcons).map(item => ({code: item, label: item}));
const isBrowser = typeof window !== 'undefined';

const SettingPage = (props) => {
  //#region Common State & Variables
  const commonClasses = useCommonStyles();
  const theme = useTheme();
  const dataGridTheme = `${theme.palette.type === 'dark' ? 'green' : 'blue'}-${theme.palette.type}`;
  const {t} = useTranslation('setting');
  const locale = props.ui.get('lang');
  const siteInfo = props.ui.get('siteInfo');
  const appDetail = Helper.getStateAsJSObj(props.system, 'setting.data.application'.split('.'), null);
  const sysCodes = Helper.getStateAsJSObj(props.system, 'setting.data.codes', []);
  const sysRoles = Helper.getStateAsJSObj(props.system, 'setting.data.roles', []);
  const sysConfig = Helper.getStateAsJSObj(props.system, 'setting.data.configs', []);
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);
  const appMenu = Helper.getStateAsJSObj(props.system, 'menuMgt', null);

  const sysConfigGetValueSetting = (cat, identifier, valueField, defaultValue) => {
    let returnValue = defaultValue;
    let config = sysConfig.find(item => item.category === cat && item.identifier === identifier);
    
    if(Helper.isNotNullAndUndefined(config?.value)) {
      returnValue = config.value[valueField];
    }

    return returnValue;
  }

  const [busy, setBusy] = React.useState(false);
  const [hideFab, setHideFab] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  //#endregion

  //#region General Setting State & Variables
  const [enforce_tfa, setEnforce_tfa] = React.useState(sysConfigGetValueSetting("authentication", "login.security", "enforce_tfa", false));
  const [allow_user_signup, setAllow_user_signup] = React.useState(sysConfigGetValueSetting("authentication", "login.security", "allow_user_signup", false));
  // This log_enable is for updating the system logging setting. It is not use for determine to log the error or not like logEnabled state
  const [log_enable, setLog_enable] = React.useState(sysConfigGetValueSetting("system", "system.logging", "log_enable", false));

  const [generalSettingStatus, setGeneralSettingStatus] = React.useState({show: false, type: 'success', message: ''});
  
  const loginPersistencySectionFormRef = React.useRef(null);
  const [loginPersistencySection, setLoginPersistencySection] = React.useState({mode: 'view', busy: false});
  const [loginPersistencySectionValidation, setLoginPersistencySectionValidation] = React.useState(loginPersistencyValidationInitState);
  
  const loginSecuritySectionFormRef = React.useRef(null);
  const [loginSecuritySection, setLoginSecuritySection] = React.useState({mode: 'view', busy: false});
  const [loginSecuritySectionValidation, setLoginSecuritySectionValidation] = React.useState(loginSecurityValidationInitState);

  const systemUserMgtSectionFormRef = React.useRef(null);
  const [systemUserMgtSection, setSystemUserMgtSection] = React.useState({mode: 'view', busy: false});
  const [systemUserMgtSectionValidation, setSystemUserMgtValidation] = React.useState(userMgtValidationInitState);

  const systemLoggingSectionFormRef = React.useRef(null);
  const [systemLoggingSection, setSystemLoggingSection] = React.useState({mode: 'view', busy: false});
  const [systemLoggingSectionValidation, setSystemLoggingValidation] = React.useState(systemLoggingValidationInitState);
  //#endregion

  //#region Code State & Variables
  const [selectedCode, setSelectedCode] = React.useState(null);
  const [showCodeDelete, setShowCodeDelete] = React.useState(false);
  const [codeUpdateStatus, setCodeUpdateStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const [codeDeleteStatus, setCodeDeleteStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const codeFormRef = React.useRef(null);
  const codeValidationInitState = {
    category: {error: false, touched: false, message: ''},
    code: {error: false, touched: false, message: ''},
    // text: {error: false, touched: false, message: ''},
    // text_zh_CN: {error: false, touched: false, message: ''},
    sort: {error: false, touched: false, message: ''},
    status: {error: false, touched: false, message: ''}
  };
  const codeFields = [
    {field: 'category', required: true, regExp: '', type: 'string', label: 'field.code.category'},
    {field: 'code', required: true, regExp: '', type: 'string', label: 'field.code.code'},
    // {field: 'text', required: true, regExp: '', type: 'string', label: 'field.code.text'},
    // {field: 'text_zh_CN', required: true, regExp: '', type: 'string', label: 'field.code.text_zh_CN'},
    {field: 'sort', required: true, regExp: '', type: 'string', label: 'field.code.sort'},
    {field: 'status', required: true, regExp: '', type: 'string', label: 'field.code.status'}
  ];
  if(Helper.arrayHasItem(siteInfo?.supportedLanguages)) {
    siteInfo.supportedLanguages.forEach(element => {
      codeValidationInitState[`text_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      codeFields.push({
        field: `text_${element.replace('-', '_')}`, 
        required: true, 
        regExp: '', 
        type: 'string', 
        label: `field.code.text_${element.replace('-', '_')}`
      });
    });    
  }
  const [codeValidation, setCodeValidation] = React.useState(codeValidationInitState);
  const [gridCodeRef, setGridCodeRef] = React.useState(null);
  const [filterCodeValue, setFilterCodeValue] = React.useState([
    { name: 'category', operator: 'eq', type: 'string', value: '' },
    { name: 'code', operator: 'contains', type: 'string', value: '' },
    { name: 'text', operator: 'contains', type: 'string', value: '' },
    { name: 'sort', operator: 'eq', type: 'string', value: '' },
    { name: 'status', operator: 'eq', type: 'string', value: '' }
  ]);
  const codeCategory = sysCodes.filter(item => item.category === 'system.codecategory');
  const codeColumns = [
    { name: 'category', defaultFlex: 2, header: t('datagrid.code.header.category'), 
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: t('datagrid.code.filter.all'),
        dataSource: codeCategory.map(item => ({id: item.code, label: item.text}))
      },
      render: ({value}) => {
        let cat = codeCategory.find(item => item.code === value);
        if(Helper.isNotNullAndUndefined(cat)) {
          return cat.text;
        }
        else {
          return value;
        }
      }
    },
    { name: 'code', defaultFlex: 2, header: t('datagrid.code.header.code'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'text', defaultFlex: 3, header: t('datagrid.code.header.text'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      },
      render: ({data, value}) => {
        let returnValue = value;
        let otherLocale = ''; 

        if(Helper.arrayHasItem(data?.translations)) {
          data.translations.forEach(element => {
            if(Helper.stringHasValue(element.text)) {
              if(Helper.stringHasValue(otherLocale))
                otherLocale += ', ';

              otherLocale += element.text;
            }
          });
        }

        if(Helper.stringHasValue(otherLocale)) {
          returnValue += ` (${otherLocale})`
        }
        
        return returnValue;
        
      }
    },
    { name: 'sort', defaultFlex: 1, textAlign: 'center', header: t('datagrid.code.header.sort'), 
      defaultVisible: !isMobile,  
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'status', defaultFlex: 1, textAlign: 'center', header: t('datagrid.code.header.status'),
      defaultVisible: !isMobile,  
      filterEditor: SelectFilter,
      enableColumnFilterContextMenu: false,
      filterEditorProps: {
        placeholder: t('datagrid.code.filter.all'),
        dataSource: sysCodes.filter(item => item.category === 'general.status').map(item => ({id: item.code, label: item.text}))
      },
      render: ({value}) => {
        let code = sysCodes.find(item => item.code === value && item.category === 'general.status');
        if(Helper.isNotNullAndUndefined(code)) {
          return code.text;
        }
        else {
          return value;
        }
      }
    }
  ];

  const loadCodeData = ({ skip, limit, sortInfo, filterValue }) => {
    const sortFields = [];
    // If have sort info
    if(Helper.isNotNullAndUndefined(sortInfo)) {
      if(Array.isArray(sortInfo)) {
        sortInfo.forEach(item => {
          sortFields.push(`${item.dir === 1 ? '' : '-'}${item.id}`)
        });
      }
      else {
        sortFields.push(`${sortInfo.dir === 1 ? '' : '-'}${sortInfo.id}`);
      }
    }
  
    let apiFilter = Helper.populateSearchFilter(filterValue);
    
    return Api.codeGetList({
        meta: 'filter_count', // Get the total record count
        fields: ['id', 'category', 'code', 'text', 'sort', 'status',
                 'translations.id', 'translations.languages_code', 'translations.text'],
        filter: apiFilter,
        sort: sortFields,
        offset: skip,
        limit: limit
      })
      .then(result => {
        // let data = result.data.map(item => {
        //   item.last_access = Helper.stringHasValue(item.last_access) ? moment(item.last_access).toDate() : null;
        //   return item;
        // });
        return Promise.resolve({ data: result.data, count: result?.meta.filter_count });
      })
      .catch(err => {
        if(logEnabled) {
          err.message += ' - Api.userGetList Error @ /system/usermgt.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(err, {
                session: props.session.toJS()
              })
            )
          );
        }
  
        consoleLogEnabled && console.error('userUpdate Error: ', err);
        return Promise.reject(err);
      })
  }

  const codeDataSource = React.useCallback(loadCodeData, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //#endregion

  //#region Role State & Variables
  const [selectedRoleSettingId, setSelectedRoleSettingId] = React.useState('');
  const [roleUpdateStatus, setRoleUpdateStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const roleFormRef = React.useRef(new Array());
  const roleValidationInitState = {
    home_url: {error: false, touched: false, message: ''}
  };
  const roleFields = [
    {field: 'home_url', required: true, regExp: '', type: 'string', label: 'field.role.home_url'}
  ];
  if(Helper.arrayHasItem(siteInfo?.supportedLanguages)) {
    siteInfo.supportedLanguages.forEach(element => {
      roleValidationInitState[`name_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      roleValidationInitState[`desc_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      roleFields.push({
        field: `name_${element.replace('-', '_')}`, 
        required: true, 
        regExp: '', 
        type: 'string', 
        label: `field.role.name_${element.replace('-', '_')}`
      });
      roleFields.push({
        field: `desc_${element.replace('-', '_')}`, 
        required: true, 
        regExp: '', 
        type: 'string', 
        label: `field.role.desc_${element.replace('-', '_')}`
      });
    });    
  }
  const [roleValidation, setRoleValidation] = React.useState(roleValidationInitState);
  //#endregion

  //#region Menu State & Variables
  const [menuFormMode, setMenuFormMode] = React.useState('view');
  const [menuIsPublic, setMenuIsPublic] = React.useState(false);
  const [showMenuDelete, setShowMenuDelete] = React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState(null);
  const [menuUpdateStatus, setMenuUpdateStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const [menuDeleteStatus, setMenuDeleteStatus] = React.useState(0); // 0 - no action, 1 - Delete success, 2 - Delete failed
  const menuFormRef = React.useRef(null);
  const menuValidationInitState = {
    route: {error: false, touched: false, message: ''},
    // is_public: {error: false, touched: false, message: ''},
    // role: {error: false, touched: false, message: ''},
    date_effective: {error: false, touched: false, message: ''},
    date_expired: {error: false, touched: false, message: ''},
    parent_menu: {error: false, touched: false, message: ''},
    // is_container: {error: false, touched: false, message: ''},
    icon: {error: false, touched: false, message: ''},
    param_query: {error: false, touched: false, message: ''},
    param_target: {error: false, touched: false, message: ''},
    param_specs: {error: false, touched: false, message: ''},
    sort: {error: false, touched: false, message: ''},
    status: {error: false, touched: false, message: ''}
  };
  const menuFields = [
    {field: 'route', required: true, regExp: '', type: 'string', label: 'field.menu.route'},
    //{field: 'is_public', required: true, regExp: '', type: 'string', label: 'field.menu.is_public'},
    // {field: 'role', required: false, regExp: '', type: 'string', label: 'field.menu.role'},
    {field: 'date_effective', required: false, regExp: '', type: 'string', label: 'field.menu.date_effective'},
    {field: 'date_expired', required: false, regExp: '', type: 'string', label: 'field.menu.date_expired'},
    {field: 'parent_menu', required: false, regExp: '', type: 'string', label: 'field.menu.parent_menu'},
    //{field: 'is_container', required: false, regExp: '', type: 'string', label: 'field.menu.xxx'},
    {field: 'icon', required: false, regExp: '', type: 'string', label: 'field.menu.icon'},
    {field: 'param_query', required: false, regExp: '', type: 'string', label: 'field.menu.param.query'},
    {field: 'param_target', required: false, regExp: '', type: 'string', label: 'field.menu.param.target'},
    {field: 'param_specs', required: false, regExp: '', type: 'string', label: 'field.menu.param.specs'},
    {field: 'sort', required: true, regExp: '', type: 'string', label: 'field.menu.sort'},
    {field: 'status', required: true, regExp: '', type: 'string', label: 'field.menu.status'}
  ];
  if(Helper.arrayHasItem(siteInfo?.supportedLanguages)) {
    siteInfo.supportedLanguages.forEach(element => {
      menuValidationInitState[`name_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      menuValidationInitState[`tooltip_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      menuFields.push({
        field: `name_${element.replace('-', '_')}`, 
        required: true, 
        regExp: '', 
        type: 'string', 
        label: `field.menu.name_${element.replace('-', '_')}`
      });
      menuFields.push({
        field: `tooltip_${element.replace('-', '_')}`, 
        required: true, 
        regExp: '', 
        type: 'string', 
        label: `field.menu.tooltip_${element.replace('-', '_')}`
      });
    });    
  }
  const [menuValidation, setMenuValidation] = React.useState(menuValidationInitState);
  //#endregion

  //#region Screen State & Variables
  const [selectedScreen, setSelectedScreen] = React.useState(null);
  const [screenFormMode, setScreenFormMode] = React.useState('view');
  const [screenIsPublic, setScreenIsPublic] = React.useState(false);
  const [showScreenDelete, setShowScreenDelete] = React.useState(false);
  const [screenUpdateStatus, setScreenUpdateStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const [screenDeleteStatus, setScreenDeleteStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const screenFormRef = React.useRef(null);
  const screenValidationInitState = {
    // name: {error: false, touched: false, message: ''},
    // title: {error: false, touched: false, message: ''},
    // description: {error: false, touched: false, message: ''},
    route: {error: false, touched: false, message: ''},
    date_effective: {error: false, touched: false, message: ''},
    date_expired: {error: false, touched: false, message: ''},
    sort: {error: false, touched: false, message: ''},
    status: {error: false, touched: false, message: ''}
  };
  const screenFields = [
    // {field: 'name', required: true, regExp: '', type: 'string', label: 'field.screen.name'},
    // {field: 'title', required: true, regExp: '', type: 'string', label: 'field.screen.title'},
    // {field: 'description', required: false, regExp: '', type: 'string', label: 'field.screen.description'},
    {field: 'route', required: true, regExp: '', type: 'string', label: 'field.screen.route'},
    {field: 'date_effective', required: false, regExp: '', type: 'string', label: 'field.screen.date_effective'},
    {field: 'date_expired', required: false, regExp: '', type: 'string', label: 'field.screen.date_expired'},
    {field: 'sort', required: true, regExp: '', type: 'string', label: 'field.screen.sort'},
    {field: 'status', required: true, regExp: '', type: 'string', label: 'field.screen.status'}
  ];
  if(Helper.arrayHasItem(siteInfo?.supportedLanguages)) {
    siteInfo.supportedLanguages.forEach(element => {
      screenValidationInitState[`title_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      screenValidationInitState[`description_${element.replace('-', '_')}`] = {error: false, touched: false, message: ''};
      screenFields.push({
        field: `title_${element.replace('-', '_')}`, 
        required: true, 
        regExp: '', 
        type: 'string', 
        label: `field.screen.title_${element.replace('-', '_')}`
      });
      screenFields.push({
        field: `description_${element.replace('-', '_')}`, 
        required: false, 
        regExp: '', 
        type: 'string', 
        label: `field.screen.description_${element.replace('-', '_')}`
      });

    });    
  }
  const [screenValidation, setScreenValidation] = React.useState(screenValidationInitState);
  const [gridScreenRef, setGridScreenRef] = React.useState(null);
  const [filterScreenValue, setFilterScreenValue] = React.useState([
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'title', operator: 'contains', type: 'string', value: '' },
    { name: 'route', operator: 'contains', type: 'string', value: '' },
    { name: 'is_public', operator: 'eq', type: 'bool', value: '' },
    { name: 'date_effective', operator: 'afterOrOn', type: 'date', value: '' },
    { name: 'date_expired', operator: 'beforeOrOn', type: 'date', value: '' },
    { name: 'status', operator: 'eq', type: 'string', value: '' }
  ]);
  const screenColumns = [
    // { name: 'name', defaultFlex: 2, header: t('datagrid.screen.header.name'), 
    //   filterEditorProps: {
    //     placeholder: t('datagrid.filter.caseSensitiveSearch')
    //   }
    // },
    { name: 'title', defaultFlex: 3, header: t('datagrid.screen.header.title'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      },
      render: ({data, value}) => {
        let returnValue = value;
        let otherLocale = ''; 

        if(Helper.arrayHasItem(data?.translations)) {
          data.translations.forEach(element => {
            if(Helper.stringHasValue(element.title)) {
              if(Helper.stringHasValue(otherLocale))
                otherLocale += ', ';

              otherLocale += element.title;
            }
          });
        }

        if(Helper.stringHasValue(otherLocale)) {
          returnValue += ` (${otherLocale})`
        }
        
        return returnValue;
      }
    },
    { name: 'route', defaultFlex: 3, header: t('datagrid.screen.header.route'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'is_public', defaultFlex: 1, textAlign: 'center', header: t('datagrid.screen.header.is_public'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      },
      enableColumnFilterContextMenu: false,
      filterEditor: BoolFilter,
      render: ({value}) => {
        return value ? <MUIIcons.Check /> : <MUIIcons.Close />
      }
    },
    { name: 'date_effective', defaultFlex: 2, type: 'date', header: t('datagrid.screen.header.date_effective'), 
      defaultVisible: !isMobile,
      // need to specify dateFormat
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: t('datagrid.screen.header.date_effective')
      },
      render: ({ value, cellProps: { dateFormat } }) => 
        Helper.stringHasValue(value) ? UIHelper.formatDate(value, locale, dateFormat) : '',
    },
    { name: 'date_expired', defaultFlex: 2, type: 'date', header: t('datagrid.screen.header.date_expired'), 
      defaultVisible: !isMobile,
      // need to specify dateFormat
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: t('datagrid.screen.header.date_expired')
      },
      render: ({ value, cellProps: { dateFormat } }) => 
        Helper.stringHasValue(value) ? UIHelper.formatDate(value, locale, dateFormat) : '',
    },
    { name: 'status', defaultFlex: 1, textAlign: 'center', header: t('datagrid.screen.header.status'),
      defaultVisible: !isMobile,  
      filterEditor: SelectFilter,
      enableColumnFilterContextMenu: false,
      filterEditorProps: {
        placeholder: t('datagrid.code.filter.all'),
        dataSource: sysCodes.filter(item => item.category === 'general.status').map(item => ({id: item.code, label: item.text}))
      },
      render: ({value}) => {
        let code = sysCodes.find(item => item.code === value && item.category === 'general.status');
        if(Helper.isNotNullAndUndefined(code)) {
          return code.text;
        }
        else {
          return value;
        }
      }
    }
  ];

  const loadScreenData = ({ skip, limit, sortInfo, filterValue }) => {
    const sortFields = [];
    // If have sort info
    if(Helper.isNotNullAndUndefined(sortInfo)) {
      if(Array.isArray(sortInfo)) {
        sortInfo.forEach(item => {
          sortFields.push(`${item.dir === 1 ? '' : '-'}${item.id}`)
        });
      }
      else {
        sortFields.push(`${sortInfo.dir === 1 ? '' : '-'}${sortInfo.id}`);
      }
    }
  
    let apiFilter = Helper.populateSearchFilter(filterValue);
    
    return Api.screenGetList({
        meta: 'filter_count', // Get the total record count
        fields: ['id', 'name', 'title', 'description', 'route', 'is_public', 
                 'role.id', 'role.directus_roles_id', 'date_effective', 'date_expired', 'sort', 'status',
                 'translations.id', 'translations.languages_code', 'translations.title', 'translations.description'],
        filter: apiFilter,
        sort: sortFields,
        offset: skip,
        limit: limit
      })
      .then(result => {
        // let data = result.data.map(item => {
        //   item.last_access = Helper.stringHasValue(item.last_access) ? moment(item.last_access).toDate() : null;
        //   return item;
        // });
        return Promise.resolve({ data: result.data, count: result?.meta.filter_count });
      })
      .catch(err => {
        if(logEnabled) {
          err.message += ' - Api.screenGetList Error @ /system/usermgt.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(err, {
                session: props.session.toJS()
              })
            )
          );
        }
  
        consoleLogEnabled && console.error('loadScreenData Error: ', err);
        return Promise.reject(err);
      })
  }

  const screenDataSource = React.useCallback(loadScreenData, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //#endregion

  //#region Tab Related Methods
  const onTabChange = (event, newValue) => {
    //console.log('onTabChange: ', appMenu);
    if(newValue === 4 && selectedTab !== newValue) {
      props.dispatch(
        systemActions.settingMenuGet(process.env.APP_ID)
      );
    }

    setSelectedTab(newValue);
  };

  const onChangeTabIndex = (index) => {
    //console.log('onChangeTabIndex: ', appMenu);
    if(index === 4 && selectedTab !== index) {
      props.dispatch(
        systemActions.settingMenuGet(process.env.APP_ID)
      );
    }

    setSelectedTab(index);
  };
  //#endregion

  const populateAppInfo = () => {
    const labelStyle = isMobile ? {display: 'flex', justifyContent: 'center'} : { display: 'flex', justifyContent: 'flex-end', width: 120, marginRight: 10 };
    const valueStyle = isMobile ? {display: 'flex', justifyContent: 'center', fontWeight: 'bold', marginBottom: 10} : { fontWeight: 'bold' };
    return Helper.isNotNullAndUndefined(appDetail) ? <>
      <div className={isMobile ? commonClasses.flexColumn : commonClasses.flexRow}>
        <Typography style={labelStyle}>App Name:</Typography>
        <Typography style={valueStyle}>{appDetail.name}</Typography>
      </div>
      <div className={isMobile ? commonClasses.flexColumn : commonClasses.flexRow}>
        <Typography style={labelStyle}>App Bundle:</Typography>
        <Typography style={valueStyle}>{appDetail.identifier}</Typography>
      </div>
      <div className={isMobile ? commonClasses.flexColumn : commonClasses.flexRow}>
        <Typography style={labelStyle}>Description:</Typography>
        <Typography style={valueStyle}>{appDetail.description}</Typography>
      </div>
      <div className={isMobile ? commonClasses.flexColumn : commonClasses.flexRow}>
        <Typography style={labelStyle}>App URL:</Typography>
        <Typography style={valueStyle}>{appDetail.url}</Typography>
      </div>
      <div className={isMobile ? commonClasses.flexColumn : commonClasses.flexRow}>
        <Typography style={labelStyle}>App Version:</Typography>
        <Typography style={valueStyle}>{packageJson.version}</Typography>
      </div>
      <div className={isMobile ? commonClasses.flexColumn : commonClasses.flexRow}>
        <Typography style={labelStyle} >Depenencies:</Typography>
        <div className={commonClasses.flexColumn}>
          {
            Object.keys(packageJson.dependencies).map(item => {
              return <Typography key={`dependencies_${item}`} style={valueStyle}>
                {`${item} ➙ ${packageJson.dependencies[item]}`}
              </Typography>;
            })
          }
        </div>
      </div>
    </>
    :
    null
  }

  //#region General Setting Methods
  const onGeneralSettingEditClick = (config) => {
    switch (config.identifier.toLowerCase()) {
      case 'login.persistency':
        setLoginPersistencySection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'edit';
          return newValue;
        });
        break;

      case 'login.security':
        setLoginSecuritySection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'edit';
          return newValue;
        });
        break;

      case 'system.usermgt':
        setSystemUserMgtSection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'edit';
          return newValue;
        });
        break;

      case 'system.logging':
        setSystemLoggingSection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'edit';
          return newValue;
        });
        break;

      default:
        break;
    }
  }

  const onGeneralSettingCancelClick = (config) => {
    switch (config.identifier.toLowerCase()) {
      case 'login.persistency':
        setLoginPersistencySection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'view';
          return newValue;
        });
        break;

      case 'login.security':
        setLoginSecuritySection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'view';
          return newValue;
        });
        setEnforce_tfa(sysConfigGetValueSetting("authentication", "login.security", "enforce_tfa", false));
        setAllow_user_signup(sysConfigGetValueSetting("authentication", "login.security", "allow_user_signup", false));
        break;

      case 'system.usermgt':
        setSystemUserMgtSection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'view';
          return newValue;
        });
        break;

      case 'system.logging':
        setSystemLoggingSection(value => {
          let newValue = Helper.deepCopy(value);
          newValue.mode = 'view';
          return newValue;
        });
        setLog_enable(sysConfigGetValueSetting("system", "system.logging", "log_enable", false));
        break;

      default:
        break;
    }
  }

  const onGeneralSettingSaveClick = (config) => {
    switch (config.identifier.toLowerCase()) {
      case 'login.persistency':
        updateLoginPersistency(config);
        break;

      case 'login.security':
        updateLoginSecurity(config);
        break;

      case 'system.usermgt':
        updateUserManagement(config);
        break;

      case 'system.logging':
        updateSystemLogging(config);
        break;

      default:
        break;
    }
  };

  const updateLoginPersistency = (config) => {
    let valid = Helper.validateFormRef(loginPersistencySectionFormRef, loginPersistencySectionFields, loginPersistencySectionValidation, setLoginPersistencySectionValidation, t);
    if(valid) {
      setLoginPersistencySection(value => {
        let newValue = Helper.deepCopy(value);
        newValue.mode = 'view';
        newValue.busy = true;
        return newValue;
      });

      let formData = Helper.deepCopy(config.value);

      // Update the formData var with the actual data in the from
      loginPersistencySectionFields.forEach(item => {
        if(Helper.isNotNullAndUndefined(loginPersistencySectionFormRef.current.elements[item.field])) {
          formData[item.field] = Number.parseInt(Helper.carefullyGetValue(loginPersistencySectionFormRef, `current.elements.${item.field}.value`.split('.'), 0));
        }
      });

      // Get the value of the checkbox
      formData.allow_rememberme = loginPersistencySectionFormRef.current.elements.allow_rememberme.checked;
      formData.app_id = process.env.APP_ID;
      
      props.dispatch(
        systemActions.settingLoginPersistencySet(formData, (success, result) => {
          setLoginPersistencySection(value => {
            let newValue = Helper.deepCopy(value);
            newValue.mode = 'view';
            newValue.busy = false;
            return newValue;
          });

          if(success) {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'success';
              newValue.message = t('loginPersistencySection.updatestatus.success');
              return newValue;
            });
          }
          else {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'error';
              newValue.message = t('loginPersistencySection.updatestatus.error');
              return newValue;
            });

            if(logEnabled) {
              result.message += ' - systemActions.settingLoginPersistencySet Error @ /system/setting.js'
              
              props.dispatch(
                loggingActions.loggingNew(
                  Helper.populateLoggingDetail(result, {
                    session: props.session.toJS()
                  })
                )
              );
            }
            consoleLogEnabled && console.error('systemActions.settingLoginPersistencySet Error @ /system/setting.js: ', result);
          }
        })
      );
    }
  };

  const updateLoginSecurity = (config) => {
    let valid = Helper.validateFormRef(loginSecuritySectionFormRef, loginSecuritySectionFields, loginSecuritySectionValidation, setLoginSecuritySectionValidation, t);
    if(valid) {
      setLoginSecuritySection(value => {
        let newValue = Helper.deepCopy(value);
        newValue.mode = 'view';
        newValue.busy = true;
        return newValue;
      });

      let formData = Helper.deepCopy(config.value);
      delete formData.authenticator; // Don't allow user to change authenticator

      // Update the formData var with the actual data in the from
      loginSecuritySectionFields.forEach(item => {
        if(Helper.isNotNullAndUndefined(loginSecuritySectionFormRef.current.elements[item.field])) {
          formData[item.field] = Helper.carefullyGetValue(loginSecuritySectionFormRef, `current.elements.${item.field}.value`.split('.'), '');
        }
      });

      // Get the value of the checkbox
      formData.enable_tfa = loginSecuritySectionFormRef.current.elements.enable_tfa.checked;
      formData.enforce_tfa = loginSecuritySectionFormRef.current.elements.enforce_tfa.checked;
      formData.user_allow_disable_tfa = loginSecuritySectionFormRef.current.elements.user_allow_disable_tfa.checked;
      formData.allow_user_signup = loginSecuritySectionFormRef.current.elements.allow_user_signup.checked;
      formData.app_id = process.env.APP_ID;
      
      props.dispatch(
        systemActions.settingLoginSecuritySet(formData, (success, result) => {
          setLoginSecuritySection(value => {
            let newValue = Helper.deepCopy(value);
            newValue.mode = 'view';
            newValue.busy = false;
            return newValue;
          });

          if(success) {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'success';
              newValue.message = t('loginSecuritySection.updatestatus.success');
              return newValue;
            });
          }
          else {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'error';
              newValue.message = t('loginSecuritySection.updatestatus.error');
              return newValue;
            });

            setEnforce_tfa(sysConfigGetValueSetting("authentication", "login.security", "enforce_tfa", false));
            setAllow_user_signup(sysConfigGetValueSetting("authentication", "login.security", "allow_user_signup", false));

            if(logEnabled) {
              result.message += ' - systemActions.setLoginSecuritySection Error @ /system/setting.js'
              
              props.dispatch(
                loggingActions.loggingNew(
                  Helper.populateLoggingDetail(result, {
                    session: props.session.toJS()
                  })
                )
              );
            }
            consoleLogEnabled && console.error('systemActions.setLoginSecuritySection Error @ /system/setting.js: ', result);
          }
        })
      );
    }
  };

  const updateUserManagement = (config) => {
    let valid = Helper.validateFormRef(systemUserMgtSectionFormRef, userMgtSectionFields, systemUserMgtSectionValidation, setSystemUserMgtValidation, t);
    if(valid) {
      setSystemUserMgtSection(value => {
        let newValue = Helper.deepCopy(value);
        newValue.mode = 'view';
        newValue.busy = true;
        return newValue;
      });

      let formData = Helper.deepCopy(config.value);
      
      // Update the formData var with the actual data in the from
      // No text field to update from form
      // userMgtSectionFields.forEach(item => {
      //   if(Helper.isNotNullAndUndefined(systemUserMgtSectionFormRef.current.elements[item.field])) {
      //     formData[item.field] = Helper.carefullyGetValue(systemUserMgtSectionFormRef, `current.elements.${item.field}.value`.split('.'), '');
      //   }
      // });

      // Get the value of the checkbox
      formData.allow_create = systemUserMgtSectionFormRef.current.elements.allow_create.checked;
      formData.allow_invite = systemUserMgtSectionFormRef.current.elements.allow_invite.checked;
      formData.allow_edit = systemUserMgtSectionFormRef.current.elements.allow_edit.checked;
      formData.allow_delete = systemUserMgtSectionFormRef.current.elements.allow_delete.checked;
      formData.app_id = process.env.APP_ID;
      
      props.dispatch(
        systemActions.settingUserMgtSet(formData, (success, result) => {
          setSystemUserMgtSection(value => {
            let newValue = Helper.deepCopy(value);
            newValue.mode = 'view';
            newValue.busy = false;
            return newValue;
          });

          if(success) {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'success';
              newValue.message = t('systemUserMgtSection.updatestatus.success');
              return newValue;
            });
          }
          else {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'error';
              newValue.message = t('systemUserMgtSection.updatestatus.error');
              return newValue;
            });

            if(logEnabled) {
              result.message += ' - systemActions.settingUserMgtSet Error @ /system/setting.js'
              
              props.dispatch(
                loggingActions.loggingNew(
                  Helper.populateLoggingDetail(result, {
                    session: props.session.toJS()
                  })
                )
              );
            }
            consoleLogEnabled && console.error('systemActions.settingUserMgtSet Error @ /system/setting.js: ', result);
          }
        })
      );
    }
  };

  const updateSystemLogging = (config) => {
    let valid = Helper.validateFormRef(systemLoggingSectionFormRef, systemLoggingSectionFields, systemLoggingSectionValidation, setSystemLoggingValidation, t);
    if(valid) {
      setSystemLoggingSection(value => {
        let newValue = Helper.deepCopy(value);
        newValue.mode = 'view';
        newValue.busy = true;
        return newValue;
      });

      let formData = Helper.deepCopy(config.value);

      // Update the formData var with the actual data in the from
      systemLoggingSectionFields.forEach(item => {
        if(Helper.isNotNullAndUndefined(systemLoggingSectionFormRef.current.elements[item.field])) {
          formData[item.field] = Number.parseInt(Helper.carefullyGetValue(systemLoggingSectionFormRef, `current.elements.${item.field}.value`.split('.'), 0));
        }
      });

      // Get the value of the checkbox
      formData.console_log_enable = systemLoggingSectionFormRef.current.elements.console_log_enable.checked;
      formData.log_enable = systemLoggingSectionFormRef.current.elements.log_enable.checked;
      formData.app_id = process.env.APP_ID;
      
      props.dispatch(
        systemActions.settingLoggingSet(formData, (success, result) => {
          setSystemLoggingSection(value => {
            let newValue = Helper.deepCopy(value);
            newValue.mode = 'view';
            newValue.busy = false;
            return newValue;
          });

          if(success) {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'success';
              newValue.message = t('systemLoggingSection.updatestatus.success');
              return newValue;
            });
          }
          else {
            setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = true;
              newValue.type = 'error';
              newValue.message = t('systemLoggingSection.updatestatus.error');
              return newValue;
            });

            if(logEnabled) {
              result.message += ' - systemActions.settingLoggingSet Error @ /system/setting.js'
              
              props.dispatch(
                loggingActions.loggingNew(
                  Helper.populateLoggingDetail(result, {
                    session: props.session.toJS()
                  })
                )
              );
            }
            consoleLogEnabled && console.error('systemActions.settingLoggingSet Error @ /system/setting.js: ', result);
          }
        })
      );
    }
  };

  const populateLoginPersistencyUI = (config, sectionStyle) => {
    let fieldStyle = {flex: 1, minWidth: 150};
    return <Section 
      key={`sys_setting_${config.name}`} 
      label={config.name} sectionStyle={sectionStyle} 
      busy={loginPersistencySection.busy}
      toolbar={
        loginPersistencySection.mode === 'view' ?
        <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingEditClick(config)}>
          <MUIIcons.Edit fontSize='small' />
        </IconButton>
        :
        <>
          <IconButton color='secondary' className={commonClasses.componentSectionToolbarButtonSecondary} onClick={_ => onGeneralSettingSaveClick(config)}>
            <MUIIcons.Save fontSize='small' />
          </IconButton>
          <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingCancelClick(config)}>
            <MUIIcons.CloseOutlined fontSize='small' />
          </IconButton>
        </>
      }
    >
      <Typography gutterBottom>{config.description}</Typography>
      {
        loginPersistencySection.mode === 'view' ? 
        <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
          <FieldValue containerStyle={fieldStyle} value={`${Helper.carefullyGetValue(config, 'value.heartbeat_duration'.split('.'), 0) / 1000} ${t('unit.second')}`} label={t('loginPersistencySection.field.heartbeat_duration')} tooltip={t('loginPersistencySection.field.heartbeat_duration.tooltip').replace('{value}', `${Helper.carefullyGetValue(config, 'value.heartbeat_duration'.split('.'), 0) / 1000} ${t('unit.second')}`)}/>  
          <FieldValue containerStyle={fieldStyle} value={`${Helper.carefullyGetValue(config, 'value.heartbeat_allowance'.split('.'), 0) / 1000} ${t('unit.second')}`} label={t('loginPersistencySection.field.heartbeat_allowance')} tooltip={t('loginPersistencySection.field.heartbeat_allowance.tooltip').replace('{value}', `${Helper.carefullyGetValue(config, 'value.heartbeat_allowance'.split('.'), 0) / 1000} ${t('unit.second')}`)}/>
          <FieldValue containerStyle={fieldStyle} value={t(`option.${config.value.allow_rememberme ? 'allow' : 'notallow'}`)} label={t('loginPersistencySection.field.allow_rememberme')} tooltip={t('loginPersistencySection.field.allow_rememberme.tooltip').replace('{value}', t(`option.${config.value.allow_rememberme ? 'allow' : 'notallow'}`))} />
          <FieldValue containerStyle={fieldStyle} value={Helper.carefullyGetValue(config, 'value.idle_timeout'.split('.'), 0) === 0 ? t('loginPersistencySection.field.idle_timeout.not_using') : `${Helper.carefullyGetValue(config, 'value.idle_timeout'.split('.'), 0) / 60000} ${t('unit.minute')}`} label={t('loginPersistencySection.field.idle_timeout')} tooltip={t('loginPersistencySection.field.idle_timeout.tooltip').replace('{value}', `${Helper.carefullyGetValue(config, 'value.idle_timeout'.split('.'), 0) / 60000} ${t('unit.minute')}`)}/>
        </div>
        :
        <>
          <form ref={loginPersistencySectionFormRef} 
            className={clsx(commonClasses.flexRow, commonClasses.flexWrap)} 
            style={{marginTop: 10}}
            onSubmit={(event) => {
              onGeneralSettingSaveClick(config);
              event.preventDefault();
            }}
          >
            <DropDownList 
              id={'heartbeat_duration'}
              style={fieldStyle}
              labelText={t('loginPersistencySection.field.heartbeat_duration')}
              options={
                UIHelper.populateDDLNumberOptions(5000, 5000, 12, optValue => {
                  return `${optValue / 1000} ${t('unit.second')}`;
                })
              }
              defaultValue={Helper.carefullyGetValue(config, 'value.heartbeat_duration'.split('.'), 0).toString()}
              error={loginPersistencySectionValidation.heartbeat_duration.error}
              helperText={loginPersistencySectionValidation.heartbeat_duration.message}
            />

            <DropDownList 
              id={'heartbeat_allowance'}
              style={fieldStyle}
              labelText={t('loginPersistencySection.field.heartbeat_allowance')}
              options={
                UIHelper.populateDDLNumberOptions(2000, 2000, 15, optValue => {
                  return `${optValue / 1000} ${t('unit.second')}`;
                })
              }
              defaultValue={Helper.carefullyGetValue(config, 'value.heartbeat_allowance'.split('.'), 0).toString()}
              error={loginPersistencySectionValidation.heartbeat_allowance.error}
              helperText={loginPersistencySectionValidation.heartbeat_allowance.message}
            />

            <FormControlLabel
              style={fieldStyle}
              control={
                <Switch 
                  id={'switch_allow_rememberme'}
                  inputProps={{id: 'allow_rememberme'}}
                  color="primary"
                  defaultChecked={Helper.carefullyGetValue(config, 'value.allow_rememberme'.split('.'), false)}
                  // error={loginPersistencySectionValidation.allow_rememberme.error}
                  // helperText={loginPersistencySectionValidation.allow_rememberme.message}
                />
              }
              label={t('loginPersistencySection.field.allow_rememberme')}
            />

            <DropDownList 
              id={'idle_timeout'}
              style={fieldStyle}
              labelText={t('loginPersistencySection.field.idle_timeout')}
              options={
                UIHelper.populateDDLNumberOptions(0, 300000, 13, optValue => {
                  return optValue <= 0 ? t('loginPersistencySection.field.idle_timeout.not_using') : `${optValue / 60000} ${t('unit.minute')}`;
                })
              }
              defaultValue={Helper.carefullyGetValue(config, 'value.idle_timeout'.split('.'), 0).toString()}
              error={loginPersistencySectionValidation.idle_timeout.error}
              helperText={loginPersistencySectionValidation.idle_timeout.message}
            />
          </form>
        </>
      }
    </Section>
  }

  const populateLoginSecurityUI = (config, sectionStyle) => {
    
    let fieldStyle = {flex: 1, minWidth: 150};
    const sysRoles = Helper.getStateAsJSObj(props.system, 'setting.data.roles', []);
    const sysRoleDDL = sysRoles.map(item => {
      let roleName = Helper.isNotNullAndUndefined(item.value?.name) ? item.value.name.find(item => item.lang === locale) : null;
      let roleNameText = '';
      if(Helper.isNotNullAndUndefined(roleName)) {
        roleNameText = roleName.text;
      }
      return {
        code: item.role,
        text: roleNameText
      }
    });
    
    let selectedRole = sysRoles.find(item => item.role === config.value?.user_signup_role);
    let selectedRoleName = '';
    if(Helper.arrayHasItem(selectedRole?.value?.name)) {
      let roleName = selectedRole.value.name.find(item => item.lang === locale);
      if(Helper.isNotNullAndUndefined(roleName)) {
        selectedRoleName = roleName.text;
      }
    }


    return <Section 
      key={`sys_setting_${config.name}`} 
      label={config.name} sectionStyle={sectionStyle} 
      busy={loginSecuritySection.busy}
      toolbar={
        loginSecuritySection.mode === 'view' ?
        <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingEditClick(config)}>
          <MUIIcons.Edit fontSize='small' />
        </IconButton>
        :
        <>
          <IconButton color='secondary' className={commonClasses.componentSectionToolbarButtonSecondary} onClick={_ => onGeneralSettingSaveClick(config)}>
            <MUIIcons.Save fontSize='small' />
          </IconButton>
          <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingCancelClick(config)}>
            <MUIIcons.CloseOutlined fontSize='small' />
          </IconButton>
        </>
      }
    >
      <Typography gutterBottom>{config.description}</Typography>
      {
        loginSecuritySection.mode === 'view' ? 
        <>
          <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
            <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.enable_tfa'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('loginSecuritySection.field.enable_tfa')} tooltip={t('loginSecuritySection.field.enable_tfa.tooltip')} />
            <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.enforce_tfa'.split('.'), false) ? 'yes' : 'no'}`)} label={t('loginSecuritySection.field.enforce_tfa')} tooltip={t('loginSecuritySection.field.enforce_tfa.tooltip')} />
            <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.user_allow_disable_tfa'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('loginSecuritySection.field.user_allow_disable_tfa')} tooltip={t('loginSecuritySection.field.user_allow_disable_tfa.tooltip')} />
          </div>
          <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)} style={{marginTop: 8}}>
            <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.allow_user_signup'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('loginSecuritySection.field.allow_user_signup')} tooltip={t('loginSecuritySection.field.allow_user_signup.tooltip')} />
            <FieldValue containerStyle={fieldStyle} value={selectedRoleName} label={t('loginSecuritySection.field.user_signup_role')} tooltip={t('loginSecuritySection.field.user_signup_role.tooltip')} />
          </div>
        </>
        :
        <>
          <form ref={loginSecuritySectionFormRef} 
            className={clsx(commonClasses.flexColumn)} 
            style={{marginTop: 10}}
            onSubmit={(event) => {
              onGeneralSettingSaveClick(config);
              event.preventDefault();
            }}
          >
            <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
              <FormControlLabel
                style={fieldStyle}
                control={
                  <Switch 
                    id={'switch_enable_tfa'}
                    inputProps={{id: 'enable_tfa'}}
                    color="primary"
                    // defaultChecked={Helper.carefullyGetValue(config, 'value.enable_tfa'.split('.'), false)}
                    checked={enforce_tfa}
                    onChange={event => setEnforce_tfa(event.currentTarget.checked)}
                  />
                }
                label={t('loginSecuritySection.field.enable_tfa')}
              />
              <FormControlLabel
                style={fieldStyle}
                control={
                  <Switch 
                    id={'switch_enforce_tfa'}
                    inputProps={{id: 'enforce_tfa'}}
                    color="primary"
                    disabled={!enforce_tfa}
                    defaultChecked={Helper.carefullyGetValue(config, 'value.enforce_tfa'.split('.'), false)}
                  />
                }
                label={t('loginSecuritySection.field.enforce_tfa')}
              />
              <FormControlLabel
                style={fieldStyle}
                control={
                  <Switch 
                    id={'switch_user_allow_disable_tfa'}
                    inputProps={{id: 'user_allow_disable_tfa'}}
                    color="primary"
                    disabled={!enforce_tfa}
                    defaultChecked={Helper.carefullyGetValue(config, 'value.user_allow_disable_tfa'.split('.'), false)}
                  />
                }
                label={t('loginSecuritySection.field.user_allow_disable_tfa')}
              />
            </div>
            <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)} style={{marginTop: 8}}>
              <FormControlLabel
                style={fieldStyle}
                control={
                  <Switch 
                    id={'switch_allow_user_signup'}
                    inputProps={{id: 'allow_user_signup'}}
                    color="primary"
                    // defaultChecked={Helper.carefullyGetValue(config, 'value.allow_user_signup'.split('.'), false)}
                    checked={allow_user_signup}
                    onChange={event => setAllow_user_signup(event.currentTarget.checked)}
                  />
                }
                label={t('loginSecuritySection.field.allow_user_signup')}
              />
              <DropDownList 
                id={'user_signup_role'}
                style={fieldStyle}
                labelText={t('loginSecuritySection.field.user_signup_role')}
                options={sysRoleDDL}
                disabled={!allow_user_signup}
                defaultValue={Helper.carefullyGetValue(config, 'value.user_signup_role'.split('.'), '')}
                error={loginSecuritySectionValidation.user_signup_role.error}
                helperText={loginSecuritySectionValidation.user_signup_role.message}
              />
              
            </div>
          </form>
        </>
      }
    </Section>
  }

  const populateUserMgtUI = (config, sectionStyle) => {
    let fieldStyle = {flex: 1, minWidth: 150};

    return <Section 
      key={`sys_setting_${config.name}`} 
      label={config.name} sectionStyle={sectionStyle} 
      busy={systemUserMgtSection.busy}
      toolbar={
        systemUserMgtSection.mode === 'view' ?
        <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingEditClick(config)}>
          <MUIIcons.Edit fontSize='small' />
        </IconButton>
        :
        <>
          <IconButton color='secondary' className={commonClasses.componentSectionToolbarButtonSecondary} onClick={_ => onGeneralSettingSaveClick(config)}>
            <MUIIcons.Save fontSize='small' />
          </IconButton>
          <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingCancelClick(config)}>
            <MUIIcons.CloseOutlined fontSize='small' />
          </IconButton>
        </>
      }
    >
      <Typography gutterBottom>{config.description}</Typography>
      {
        systemUserMgtSection.mode === 'view' ? 
        <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
          <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.allow_create'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('systemUserMgtSection.field.allow_create')} tooltip={t('systemUserMgtSection.field.allow_create.tooltip').replace('{value}', t(`option.${Helper.carefullyGetValue(config, 'value.allow_create'.split('.'), false) ? 'allow' : 'notallow'}`))} />
          <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.allow_invite'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('systemUserMgtSection.field.allow_invite')} tooltip={t('systemUserMgtSection.field.allow_invite.tooltip').replace('{value}', t(`option.${Helper.carefullyGetValue(config, 'value.allow_invite'.split('.'), false) ? 'allow' : 'notallow'}`))} />
          <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.allow_edit'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('systemUserMgtSection.field.allow_edit')} tooltip={t('systemUserMgtSection.field.allow_edit.tooltip').replace('{value}', t(`option.${Helper.carefullyGetValue(config, 'value.allow_edit'.split('.'), false) ? 'allow' : 'notallow'}`))} />
          <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.allow_delete'.split('.'), false) ? 'allow' : 'notallow'}`)} label={t('systemUserMgtSection.field.allow_delete')} tooltip={t('systemUserMgtSection.field.allow_delete.tooltip').replace('{value}', t(`option.${Helper.carefullyGetValue(config, 'value.allow_delete'.split('.'), false) ? 'allow' : 'notallow'}`))} />
        </div>
        :
        <>
          <form ref={systemUserMgtSectionFormRef} 
            className={clsx(commonClasses.flexRow, commonClasses.flexWrap)} 
            style={{marginTop: 10}}
            onSubmit={(event) => {
              onGeneralSettingSaveClick(config);
              event.preventDefault();
            }}
          >
            <FormControlLabel
              style={fieldStyle}
              control={
                <Switch 
                  id={'switch_allow_create'}
                  inputProps={{id: 'allow_create'}}
                  color="primary"
                  defaultChecked={Helper.carefullyGetValue(config, 'value.allow_create'.split('.'), false)}
                />
              }
              label={t('systemUserMgtSection.field.allow_create')}
            />
            <FormControlLabel
              style={fieldStyle}
              control={
                <Switch 
                  id={'switch_allow_invite'}
                  inputProps={{id: 'allow_invite'}}
                  color="primary"
                  defaultChecked={Helper.carefullyGetValue(config, 'value.allow_invite'.split('.'), false)}
                />
              }
              label={t('systemUserMgtSection.field.allow_invite')}
            />
            <FormControlLabel
              style={fieldStyle}
              control={
                <Switch 
                  id={'switch_allow_edit'}
                  inputProps={{id: 'allow_edit'}}
                  color="primary"
                  defaultChecked={Helper.carefullyGetValue(config, 'value.allow_edit'.split('.'), false)}
                />
              }
              label={t('systemUserMgtSection.field.allow_edit')}
            />
            <FormControlLabel
              style={fieldStyle}
              control={
                <Switch 
                  id={'switch_allow_delete'}
                  inputProps={{id: 'allow_delete'}}
                  color="primary"
                  defaultChecked={Helper.carefullyGetValue(config, 'value.allow_delete'.split('.'), false)}
                />
              }
              label={t('systemUserMgtSection.field.allow_delete')}
            />
          </form>
        </>
      }
    </Section>
  }

  const populateSystemLoggingUI = (config, sectionStyle) => {
    let fieldStyle = {flex: 1, minWidth: 150};

    return <Section 
      key={`sys_setting_${config.name}`} 
      label={config.name} sectionStyle={sectionStyle} 
      busy={systemLoggingSection.busy}
      toolbar={
        systemLoggingSection.mode === 'view' ?
        <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingEditClick(config)}>
          <MUIIcons.Edit fontSize='small' />
        </IconButton>
        :
        <>
          <IconButton color='secondary' className={commonClasses.componentSectionToolbarButtonSecondary} onClick={_ => onGeneralSettingSaveClick(config)}>
            <MUIIcons.Save fontSize='small' />
          </IconButton>
          <IconButton className={commonClasses.componentSectionToolbarButton} onClick={_ => onGeneralSettingCancelClick(config)}>
            <MUIIcons.CloseOutlined fontSize='small' />
          </IconButton>
        </>
      }
    >
      <Typography gutterBottom>{config.description}</Typography>
      {
        systemLoggingSection.mode === 'view' ? 
        <>
          <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
            <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.console_log_enable'.split('.'), false) ? 'yes' : 'no'}`)} label={t('systemLoggingSection.field.console_log_enable')} tooltip={t('systemLoggingSection.field.console_log_enable.tooltip').replace('{value}', t(`option.${Helper.carefullyGetValue(config, 'value.console_log_enable'.split('.'), false) ? 'allow' : 'notallow'}`))} />
            <FieldValue containerStyle={fieldStyle} value={t(`option.${Helper.carefullyGetValue(config, 'value.log_enable'.split('.'), false) ? 'yes' : 'no'}`)} label={t('systemLoggingSection.field.log_enable')} tooltip={t('systemLoggingSection.field.log_enable.tooltip').replace('{value}', t(`option.${Helper.carefullyGetValue(config, 'value.log_enable'.split('.'), false) ? 'allow' : 'notallow'}`))} />
          </div>
          <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)} style={{marginTop: 8}}>
            <FieldValue containerStyle={fieldStyle} value={`${Helper.carefullyGetValue(config, 'value.max_log_cap'.split('.'), '')}`} label={t('systemLoggingSection.field.max_log_cap')} tooltip={t('systemLoggingSection.field.max_log_cap.tooltip').replace('{value}', Helper.carefullyGetValue(config, 'value.max_log_cap'.split('.'), ''))} />
            <FieldValue containerStyle={fieldStyle} value={`${Helper.carefullyGetValue(config, 'value.sync_batchsize'.split('.'), '')}`} label={t('systemLoggingSection.field.sync_batchsize')} tooltip={t('systemLoggingSection.field.sync_batchsize.tooltip').replace('{value}', Helper.carefullyGetValue(config, 'value.sync_batchsize'.split('.'), ''))} />
            <FieldValue containerStyle={fieldStyle} value={`${Helper.carefullyGetValue(config, 'value.sync_interval'.split('.'), 0) / 1000} ${t('unit.second')}`} label={t('systemLoggingSection.field.sync_interval')} tooltip={t('systemLoggingSection.field.sync_interval.tooltip').replace('{value}', `${Helper.carefullyGetValue(config, 'value.sync_interval'.split('.'), 0) / 1000} ${t('unit.second')}`)} />  
            <FieldValue containerStyle={fieldStyle} value={`${Helper.carefullyGetValue(config, 'value.retry_interval'.split('.'), 0) / 1000} ${t('unit.second')}`} label={t('systemLoggingSection.field.retry_interval')} tooltip={t('systemLoggingSection.field.retry_interval.tooltip').replace('{value}', `${Helper.carefullyGetValue(config, 'value.retry_interval'.split('.'), 0) / 1000} ${t('unit.second')}`)} />  
          </div>
        </>
        :
        <>
          <form ref={systemLoggingSectionFormRef} 
            className={clsx(commonClasses.flexColumn)} 
            style={{marginTop: 10}}
            onSubmit={(event) => {
              onGeneralSettingSaveClick(config);
              event.preventDefault();
            }}
          >
            <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
              <FormControlLabel
                style={fieldStyle}
                control={
                  <Switch 
                    id={'switch_console_log_enable'}
                    inputProps={{id: 'console_log_enable'}}
                    color="primary"
                    defaultChecked={Helper.carefullyGetValue(config, 'value.console_log_enable'.split('.'), false)}
                  />
                }
                label={t('systemLoggingSection.field.console_log_enable')}
              />
              <FormControlLabel
                style={fieldStyle}
                control={
                  <Switch 
                    id={'switch_log_enable'}
                    inputProps={{id: 'log_enable'}}
                    color="primary"
                    // defaultChecked={Helper.carefullyGetValue(config, 'value.log_enable'.split('.'), false)}
                    checked={log_enable}
                    onChange={event => setLog_enable(event.currentTarget.checked)}
                  />
                }
                label={t('systemLoggingSection.field.log_enable')}
              />
              
            </div>
            <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)} style={{marginTop: 8}}>
              <DropDownList 
                id={'max_log_cap'}
                style={fieldStyle}
                labelText={t('systemLoggingSection.field.max_log_cap')}
                options={
                  UIHelper.populateDDLNumberOptions(5, 5, 10)
                }
                disabled={!log_enable}
                defaultValue={Helper.carefullyGetValue(config, 'value.max_log_cap'.split('.'), 0).toString()}
                error={systemLoggingSectionValidation.max_log_cap.error}
                helperText={systemLoggingSectionValidation.max_log_cap.message}
              />
              <DropDownList 
                id={'sync_batchsize'}
                style={fieldStyle}
                labelText={t('systemLoggingSection.field.sync_batchsize')}
                options={
                  UIHelper.populateDDLNumberOptions(5, 5, 10)
                }
                disabled={!log_enable}
                defaultValue={Helper.carefullyGetValue(config, 'value.sync_batchsize'.split('.'), 0).toString()}
                error={systemLoggingSectionValidation.sync_batchsize.error}
                helperText={systemLoggingSectionValidation.sync_batchsize.message}
              />
              <DropDownList 
                id={'sync_interval'}
                style={fieldStyle}
                labelText={t('systemLoggingSection.field.sync_interval')}
                options={
                  UIHelper.populateDDLNumberOptions(10000, 10000, 12, optValue => {
                    return `${optValue / 1000} ${t('unit.second')}`;
                  })
                }
                disabled={!log_enable}
                defaultValue={Helper.carefullyGetValue(config, 'value.sync_interval'.split('.'), 0).toString()}
                error={systemLoggingSectionValidation.sync_interval.error}
                helperText={systemLoggingSectionValidation.sync_interval.message}
              />
              <DropDownList 
                id={'retry_interval'}
                style={fieldStyle}
                labelText={t('systemLoggingSection.field.retry_interval')}
                options={
                  UIHelper.populateDDLNumberOptions(30000, 10000, 22, optValue => {
                    return `${optValue / 1000} ${t('unit.second')}`;
                  })
                }
                disabled={!log_enable}
                defaultValue={Helper.carefullyGetValue(config, 'value.retry_interval'.split('.'), 0).toString()}
                error={systemLoggingSectionValidation.retry_interval.error}
                helperText={systemLoggingSectionValidation.retry_interval.message}
              />
            </div>
          </form>
        </>
      }
    </Section>
  }

  const populateSetting = () => {
    const sectionStyle = {flex: 1, minWidth: 300, minHeight: 250};
    const sections = [];

    const populateSection = (config) => {
      let controls = null;
      switch (config.identifier.toLowerCase()) {
        case 'login.persistency':
          controls = populateLoginPersistencyUI(config, sectionStyle);
          break;

        case 'login.security':
          controls = populateLoginSecurityUI(config, sectionStyle);
          break;

        case 'system.usermgt':
          controls = populateUserMgtUI(config, sectionStyle);
          break;

        case 'system.logging':
          controls = populateSystemLoggingUI(config, sectionStyle);
          break;

        default:
          break;
      }

      return controls;
    }

    if(Helper.arrayHasItem(sysConfig)) {
      sysConfig.forEach(config => {
        sections.push(populateSection(config));
      });
    }

    return <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
      {sections}
    </div>
  }
  //#endregion

  //#region Code Manage Methods
  const populateCode = () => {
    return <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
      <ReactDataGrid
        idProperty="id"
        onReady={ref => {
          setGridCodeRef(ref);
          ref?.current && ref?.current.focus();
        }}
        theme={dataGridTheme}
        style={gridStyle}
        showActiveRowIndicator={true}
        defaultActiveIndex={0}
        activateRowOnFocus={true}
        enableKeyboardNavigation={true}
        columns={codeColumns}
        dataSource={codeDataSource}
        defaultFilterValue={filterCodeValue}
        onFilterValueChange={setFilterCodeValue}
        pagination
        i18n={UIHelper.reactDataGridI18n(t)}
        showColumnMenuGroupOptions={false}
        showColumnMenuLockOptions={false}
        enableColumnAutosize={false}
        defaultLimit={isMobile ? 10 : 20}
        onRowClick={(rowProps, event) => openSelectedCode(rowProps.data)}
        onKeyDown={onGridCodeKeyDown}
      />
    </div>
  }

  const openSelectedCode = (data) => {
    let selectedData = Helper.deepCopy(data);
    // Rename the text field with lang code
    selectedData.text_en_US = selectedData.text;
    delete selectedData.text;

    if(Helper.arrayHasItem(siteInfo?.supportedLanguages)) {
      siteInfo.supportedLanguages.filter(item => item !== 'en-US').forEach(element => {
        if(Helper.arrayHasItem(data?.translations)) {
          let langText = data.translations.find(item => item.languages_code === element);
          if(Helper.isNotNullAndUndefined(langText)) {
            selectedData[`text_${element.replace('-', '_')}`] = langText.text;
          }
        }
      });
    }

    // console.log('openSelectedCode selectedData: ', selectedData);
    setSelectedCode(selectedData);
  }

  const closeSelectedCode = () => {
    setSelectedCode(null);
    setCodeValidation(codeValidationInitState);
  }

  const onGridCodeKeyDown = React.useCallback(event => {
    if(event.key === 'Enter' && gridCodeRef?.current && gridCodeRef.current.activeRowRef 
       && gridCodeRef.current.activeRowRef.current && gridCodeRef.current.activeRowRef.current.instance 
       && gridCodeRef.current.activeRowRef.current.instance.props
       && gridCodeRef.current.activeRowRef.current.instance.props.data) {
      openSelectedCode(gridCodeRef.current.activeRowRef.current.instance.props.data);
    }
  }, [gridCodeRef]);

  const updateCode = () => {
    setCodeUpdateStatus(0);
    try {
      let valid = Helper.validateFormRef(codeFormRef, codeFields, codeValidation, setCodeValidation, t);
      if(valid) {
        setBusy(true);

        let formData = Helper.deepCopy(selectedCode);

        // Update the formData var with the actual data in the from
        codeFields.forEach(item => {
          if(Helper.isNotNullAndUndefined(codeFormRef.current.elements[item.field])) {
            formData[item.field] = Helper.carefullyGetValue(codeFormRef, `current.elements.${item.field}.value`.split('.'), '');
          }
        });

        // Set the app id
        formData.application = process.env.APP_ID;

        props.dispatch(
          systemActions.settingCodeSet(formData, (success, result) => {
            setBusy(false);

            if(success) {
              // Set the code update status and refresh the grid view
              setCodeUpdateStatus(1);
              closeSelectedCode();
              gridCodeRef.current?.reload();
            }
            else {
              // set the code update status as error
              setCodeUpdateStatus(2);
              if(logEnabled) {
                result.message += ' - systemActions.settingCodeSet Error @ /system/setting.js'
                
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('systemActions.settingCodeSet Error @ /system/setting.js: ', result);
            }
          })
        );
      }
    } catch (error) {
      setBusy(false);
      setCodeUpdateStatus(2);
      if(logEnabled) {
        error.message += ' - updateCode Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('updateCode Error: ', error);
    }  
  };

  const deleteCode = () => {
    setCodeDeleteStatus(0);
    try {
      setBusy(true);

      props.dispatch(
        systemActions.settingCodeDelete(selectedCode, (success, result) => {
          setBusy(false);
          setShowCodeDelete(false);

          if(success) {
            // Set the code delete status and refresh the grid view
            setCodeDeleteStatus(1);
            closeSelectedCode();
            gridCodeRef.current?.reload();
          }
          else {
            // set the code delete status as error
            setCodeDeleteStatus(2);
            if(logEnabled) {
              result.message += ' - systemActions.settingCodeDelete Error @ /system/setting.js'
              
              props.dispatch(
                loggingActions.loggingNew(
                  Helper.populateLoggingDetail(result, {
                    session: props.session.toJS()
                  })
                )
              );
            }
            consoleLogEnabled && console.error('systemActions.settingCodeDelete Error @ /system/setting.js: ', result);
          }
        })
      );
      
    } catch (error) {
      setBusy(false);
      setCodeDeleteStatus(2);
      setShowCodeDelete(false);
      if(logEnabled) {
        error.message += ' - deleteCode Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('deleteCode Error: ', error);
    }  
  };
  //#endregion

  //#region Role Manage Methods
  const populateRole = () => {
    const fieldStyle = {flex: 1, marginBottom: 15, minWidth: 250};
    roleFormRef.current = [];
    const availRoute = Helper.getStateAsJSObj(props.system, 'setting.data.screens', [])
                       .sort((a, b) => (a.route > b.route) ? 1 : -1)
                       .map(item => ({code: item.route, label: item.route}));

    return <div className={clsx(commonClasses.flexColumn)}>
      {
        sysRoles.sort((a, b) => (a.value?.level > b.value?.level) ? 1 : -1).map(role => {
          let name = role.value?.name?.find(item => item.lang === locale);
          let desc = role.value?.desc?.find(item => item.lang === locale);
          let curHome_Url = Helper.carefullyGetValue(role, 'value.home_url'.split('.'), '');
          let routeDefaultValue = availRoute.find(item => item.code === curHome_Url);
          if(!Helper.isNotNullAndUndefined(routeDefaultValue)){
            routeDefaultValue = {code: curHome_Url, label: curHome_Url};
          } 
          // console.log('populateRole: ', role);
          // console.log('Generate roles...');
          return <Accordion key={`Accordion_${role.id}`} expanded={selectedRoleSettingId === role.id} onChange={onRoleSelect(role.id)}>
          <AccordionSummary
            expandIcon={<MUIIcons.ExpandMore />}
            aria-controls={`AccordionCtrl_${role.id}`}
            id={`AccordionSummary_${role.id}`}
          >
            <Typography style={{minWidth: 200, fontWeight: 'bold'}}>{name?.text}</Typography>
            {
              !isMobile &&
              <Typography>{`- ${desc?.text}`}</Typography>
            }
            
          </AccordionSummary>
          <AccordionDetails>
            <form ref={(ref) => roleFormRef.current.push(ref)} 
              id={role.id}
              onSubmit={(event) => {
                updateRole();
                event.preventDefault();
              }}
              className={commonClasses.flexColumn}
              style={{
                flex: 1
              }}
            >
              <Divider style={{marginBottom: 20}} />
              {
                isMobile &&
                <Typography style={{marginBottom: 20}}>{desc?.text}</Typography>
              }
              
              <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
              {
                Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
                siteInfo.supportedLanguages.map(lang => {
                  let form_name = role.value?.name?.find(item => item.lang === lang);
                  return <TextField key={`name_${lang.replace('-', '_')}`} id={`name_${lang.replace('-', '_')}`} label={t(`field.role.name_${lang.replace('-', '_')}`)} 
                    style={fieldStyle}
                    variant="outlined" 
                    defaultValue={Helper.carefullyGetValue(form_name, [`text`], '')} 
                    error={roleValidation[`name_${lang.replace('-', '_')}`].error}
                    helperText={roleValidation[`name_${lang.replace('-', '_')}`].message}
                    onBlur={(event) => Helper.validateFormField(`name_${lang.replace('-', '_')}`, event.target.value, roleFields, roleValidation, setRoleValidation, t)}
                  />
                })
              }
              </div>
              <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
              {
                Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
                siteInfo.supportedLanguages.map(lang => {
                  let form_desc = role.value?.desc?.find(item => item.lang === lang);
                  return <TextField key={`desc_${lang.replace('-', '_')}`} id={`desc_${lang.replace('-', '_')}`} label={t(`field.role.desc_${lang.replace('-', '_')}`)} 
                    style={fieldStyle}
                    variant="outlined" 
                    multiline={true}
                    maxRows={4} minRows={4}
                    defaultValue={Helper.carefullyGetValue(form_desc, [`text`], '')} 
                    error={roleValidation[`desc_${lang.replace('-', '_')}`].error}
                    helperText={roleValidation[`desc_${lang.replace('-', '_')}`].message}
                    onBlur={(event) => Helper.validateFormField(`desc_${lang.replace('-', '_')}`, event.target.value, roleFields, roleValidation, setRoleValidation, t)}
                  />
                })
              }
              </div>
              <Autocomplete
                id="home_url"
                style={fieldStyle}
                options={availRoute}
                defaultValue={routeDefaultValue} 
                autoHighlight
                freeSolo={false}
                getOptionSelected={(option) => option.code === curHome_Url}
                getOptionLabel={(option) => option.label}
                onBlur={(event) => Helper.validateFormField(`home_url`, event.target.value, roleFields, roleValidation, setRoleValidation, t)}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.label}
                  </React.Fragment>
                )}
                renderInput={(params) => {
                  return (<TextField
                    {...params}
                    label={t('field.role.home_url')}
                    variant="outlined"
                    error={roleValidation.home_url.error}
                    helperText={roleValidation.home_url.message}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}}
              />
              <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)} style={{justifyContent: 'center'}}>
                <Button
                  variant='contained'
                  onClick={onRoleSelect('')}
                >
                  {t('button.close')}
                </Button>
                <Button
                  type='submit'
                  color='primary'
                  variant='contained'
                >
                  {t('button.save')}
                </Button>
              </div>
            </form>
          </AccordionDetails>
        </Accordion>
        })
      }
    </div>
  }
  
  const onRoleSelect = (id) => (event, isExpanded) => {
    // console.log('onRoleSelect Form: ', roleFormRef);
    // console.log('onRoleSelect resetForm: ', resetForm);
    // if(Helper.arrayHasItem(roleFormRef?.current) && Helper.stringHasValue(selectedRoleSettingId)) {
    //   let curForm = roleFormRef.current.find(item => item?.id === selectedRoleSettingId);
    //   if(Helper.isNotNullAndUndefined(curForm) && resetForm) {
    //     curForm.reset();
    //   }
    // }
    setRoleValidation(roleValidationInitState);
    setSelectedRoleSettingId(isExpanded ? id : '');
  };

  const updateRole = () => {
    setRoleUpdateStatus(0);
    try {
      let curForm = null;
      if(Helper.arrayHasItem(roleFormRef?.current) && Helper.stringHasValue(selectedRoleSettingId)) {
        curForm = roleFormRef.current.find(item => item?.id === selectedRoleSettingId);
      }

      if(Helper.isNotNullAndUndefined(curForm)) {
        let valid = Helper.validateFormRef({current: curForm}, roleFields, roleValidation, setRoleValidation, t);
        if(valid) {
          setBusy(true);
          let formData = {id: selectedRoleSettingId};

          // Update the formData var with the actual data in the from
          roleFields.forEach(item => {
            if(Helper.isNotNullAndUndefined(curForm.elements[item.field])) {
              formData[item.field] = Helper.carefullyGetValue(curForm, `elements.${item.field}.value`.split('.'), '');
            }
          });

          // console.log('updateRole formData: ', formData);
          props.dispatch(
            systemActions.settingRoleSet(formData, (success, result) => {
              setBusy(false);
  
              if(success) {
                // Set the code update status and refresh the grid view
                setRoleUpdateStatus(1);
                onRoleSelect('')(null, false);
              }
              else {
                // set the code update status as error
                setRoleUpdateStatus(2);
                if(logEnabled) {
                  result.message += ' - systemActions.settingRoleSet Error @ /system/setting.js'
                  
                  props.dispatch(
                    loggingActions.loggingNew(
                      Helper.populateLoggingDetail(result, {
                        session: props.session.toJS()
                      })
                    )
                  );
                }
                consoleLogEnabled && console.error('systemActions.settingRoleSet Error @ /system/setting.js: ', result);
              }
            })
          );
        }
      }
    } catch (error) {
      setBusy(false);
      setRoleUpdateStatus(2);
      if(logEnabled) {
        error.message += ' - updateRole Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('updateRole Error: ', error);
    }
  }
  //#endregion

  //#region Menu Manage Methods
  const populateMenu = () => {
    const rootItems = !Helper.arrayHasItem(appMenu?.data) ? [] : appMenu.data.filter(item => !Helper.isNotNullAndUndefined(item?.parent_menu))
                      .sort((a, b) => (a.sort > b.sort) ? 1 : -1);
    
    return <div className={clsx(commonClasses.flexColumn)}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t('menu.title')}
          </ListSubheader>
        }
      >
        {
          rootItems.map((item, index) => {
            let name = item.name;
            let tooltip = item.tooltip;
            
            if(locale !== 'en-US' && Helper.arrayHasItem(item.translations)) {
              let trans = item.translations.find(tItem => tItem.languages_code === locale);
              if(Helper.isNotNullAndUndefined(trans)) {
                name = trans.name;
                tooltip = trans.tooltip;
              }
            }

            if(!item.is_container) {
              return (
                <ListItem key={item.id} button onClick={_ => openMenuItem(item)}>
                  <ListItemIcon>
                    <Icon source={item.icon} />
                  </ListItemIcon>
                  <ListItemText primary={isMobile ? name : `${name} - ${tooltip}`} />
                </ListItem>
              );
            }
            else {
              let menuItemWithChild = [];
        
              menuItemWithChild.push(
                <ListItem button key={item.id} onClick={_ => openMenuItem(item)}>
                  <ListItemIcon><Icon source={item.icon} /></ListItemIcon>
                  <ListItemText primary={isMobile ? name : `${name} - ${tooltip}`} />
                </ListItem>
              );
        
              menuItemWithChild = menuItemWithChild.concat(populateChildMenu(item.id, 1));
              return menuItemWithChild;
            }
          })
        }
      </List>
    </div>
  }

  const populateChildMenu = (parentId, level) => { 
    const childItems = !Helper.arrayHasItem(appMenu?.data) ? [] : appMenu.data.filter(item => item?.parent_menu === parentId)
                       .sort((a, b) => (a.sort > b.sort) ? 1 : -1);

    return(
      childItems.map((item) => {
        let name = item.name;
        let tooltip = item.tooltip;

        if(locale !== 'en-US' && Helper.arrayHasItem(item.translations)) {
          let trans = item.translations.find(tItem => tItem.languages_code === locale);
          if(Helper.isNotNullAndUndefined(trans)) {
            name = trans.name;
            tooltip = trans.tooltip;
          }
        }

        if(!item.is_container) {
          return (
            <ListItem button key={item.id} style={{marginLeft: level * 20}} onClick={_ => openMenuItem(item)}>
              <ListItemIcon><Icon source={item.icon} /></ListItemIcon>
              <ListItemText primary={isMobile ? name : `${name} - ${tooltip}`} />
            </ListItem>
          );
        }
        else {
          let menuItemWithChild = [];

          menuItemWithChild.push(
            <ListItem button key={item.id} style={{marginLeft: level * 20}} onClick={_ => openMenuItem(item)}>
              <ListItemIcon><Icon source={item.icon} /></ListItemIcon>
              <ListItemText primary={isMobile ? name : `${name} - ${tooltip}`} />
            </ListItem>
          );

          menuItemWithChild = menuItemWithChild.concat(populateChildMenu(item.id, level + 1));
          return menuItemWithChild;
        }
      })
    );
  };

  const newMenuItem = () => {
    const menuItem = {
      id: "",
      status: "draft",
      sort: 0,
      name: "",
      tooltip: "",
      route: "",
      param: null,
      icon: {
          name: ""
      },
      is_container: false,
      is_public: false,
      parent_menu: null,
      date_effective: null,
      date_expired: null,
      role: [],
      translations: null
    }
    openMenuItem(menuItem, 'edit');
  };

  const openMenuItem = (menuItem, formMode='view') => {
    console.log('openMenuItem: ', menuItem);
    setMenuIsPublic(menuItem.is_public);
    setMenuValidation(menuValidationInitState);
    setMenuFormMode(formMode);
    setSelectedMenuItem(menuItem);
    setHideFab(true);
  }

  const closeMenuItem = () => {
    setMenuIsPublic(false);
    setMenuValidation(menuValidationInitState);
    setMenuFormMode('view');
    setSelectedMenuItem(null);
    setHideFab(false);
  }

  const populateMenuForm = () => {
    const fieldStyle = {flex: 1, marginBottom: 15, minWidth: 250};
    if(!Helper.isNotNullAndUndefined(selectedMenuItem))
      return null;

    const parentItems = !Helper.arrayHasItem(appMenu?.data) ? [] 
      : appMenu.data.filter(item => item.is_container)
        .sort((a, b) => (a.sort > b.sort) ? 1 : -1)
        .map(item => ({code: item.id, text: UIHelper.getObjFieldLangText(item, 'name', locale)}));
    parentItems.unshift({code: 'none', text: t('field.menu.parent_menu.none')});
    const selectedParentMenu = parentItems.find(item => item.code === Helper.carefullyGetValue(selectedMenuItem, ['parent_menu'], 'none'));

    const availRoute = Helper.getStateAsJSObj(props.system, 'setting.data.screens', [])
                       .sort((a, b) => (a.route > b.route) ? 1 : -1)
                       .map(item => ({code: item.route, label: item.route}));

    let routeDefaultValue = availRoute.find(item => item.code === Helper.carefullyGetValue(selectedMenuItem, ['route'], ''));
    if(!Helper.isNotNullAndUndefined(routeDefaultValue)){
      routeDefaultValue = {code: Helper.carefullyGetValue(selectedMenuItem, ['route'], ''), label: Helper.carefullyGetValue(selectedMenuItem, ['route'], '')};
    } 

    const selectedStatus = sysCodes.filter(item => item.category === 'general.status').find(item => item.code === Helper.carefullyGetValue(selectedMenuItem, ['status'], ''));

    return <form ref={menuFormRef} 
      onSubmit={(event) => {
        updateMenu();
        event.preventDefault();
      }}
      className={commonClasses.flexColumn}
      style={{flex: 1}}
    >
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
        siteInfo.supportedLanguages.map(lang => {
          return menuFormMode === 'view' ? 
          <FieldValue key={`name_${lang.replace('-', '_')}`} label={t(`field.menu.name_${lang.replace('-', '_')}`)} value={UIHelper.getObjFieldLangText(selectedMenuItem, 'name', lang)} containerStyle={fieldStyle} />
          :
          <TextField key={`name_${lang.replace('-', '_')}`} id={`name_${lang.replace('-', '_')}`} label={t(`field.menu.name_${lang.replace('-', '_')}`)} 
            style={fieldStyle}
            variant="outlined" 
            required
            defaultValue={UIHelper.getObjFieldLangText(selectedMenuItem, 'name', lang)} 
            error={menuValidation[`name_${lang.replace('-', '_')}`].error}
            helperText={menuValidation[`name_${lang.replace('-', '_')}`].message}
            onBlur={(event) => Helper.validateFormField(`name_${lang.replace('-', '_')}`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />
        })
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
        siteInfo.supportedLanguages.map(lang => {
          return menuFormMode === 'view' ?
          <FieldValue key={`tooltip_${lang.replace('-', '_')}`} label={t(`field.menu.tooltip_${lang.replace('-', '_')}`)} value={UIHelper.getObjFieldLangText(selectedMenuItem, 'tooltip', lang)} containerStyle={fieldStyle} />
          :
          <TextField key={`tooltip_${lang.replace('-', '_')}`} id={`tooltip_${lang.replace('-', '_')}`} label={t(`field.menu.tooltip_${lang.replace('-', '_')}`)} 
            style={fieldStyle}
            variant="outlined" 
            multiline
            maxRows={3}
            minRows={3}
            defaultValue={UIHelper.getObjFieldLangText(selectedMenuItem, 'tooltip', lang)} 
            error={menuValidation[`tooltip_${lang.replace('-', '_')}`].error}
            helperText={menuValidation[`tooltip_${lang.replace('-', '_')}`].message}
            onBlur={(event) => Helper.validateFormField(`tooltip_${lang.replace('-', '_')}`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />
        })
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        menuFormMode === 'view' ?
        <>
          <FieldValue label={t(`field.menu.date_effective`)} value={UIHelper.formatDate(Helper.carefullyGetValue(selectedMenuItem, ['date_effective'], null), locale)} containerStyle={fieldStyle} />
          <FieldValue label={t(`field.menu.date_expired`)} value={UIHelper.formatDate(Helper.carefullyGetValue(selectedMenuItem, ['date_expired'], null), locale)} containerStyle={fieldStyle} />
        </>
        :
        <>
          <TextField key={`date_effective`} id={`date_effective`} label={t(`field.menu.date_effective`)} 
            style={fieldStyle}
            variant="outlined" 
            type='datetime-local'
            placeholder=""
            defaultValue={UIHelper.formatDate(Helper.carefullyGetValue(selectedMenuItem, ['date_effective'], null), locale, 'yyyy-MM-DDTHH:mm')} 
            error={menuValidation.date_effective.error}
            helperText={menuValidation.date_effective.message}
            onBlur={(event) => Helper.validateFormField(`date_effective`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />
          <TextField key={`date_expired`} id={`date_expired`} label={t(`field.menu.date_expired`)} 
            style={fieldStyle}
            variant="outlined" 
            type='datetime-local'
            defaultValue={UIHelper.formatDate(Helper.carefullyGetValue(selectedMenuItem, ['date_expired'], null), locale, 'yyyy-MM-DDTHH:mm')} 
            error={menuValidation.date_expired.error}
            helperText={menuValidation.date_expired.message}
            onBlur={(event) => Helper.validateFormField(`date_expired`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />
        </>
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        menuFormMode === 'view' ?
        <>
          <fieldset className={commonClasses.componentFieldValue} style={{...fieldStyle, flexDirection: 'row'}}>
            <legend style={{width: '-webkit-fit-content'}}>{t('field.menu.icon')}</legend>
            <Icon source={{name: Helper.carefullyGetValue(selectedMenuItem, 'icon.name'.split('.'), '')}} />
            <span style={{marginLeft: 10}}>{Helper.carefullyGetValue(selectedMenuItem, 'icon.name'.split('.'), '')}</span>
          </fieldset>
          <FieldValue label={t('field.menu.parent_menu')} value={selectedParentMenu.text} containerStyle={fieldStyle} />
          <FieldValue label={t('field.menu.is_container')} value={t(`option.${Helper.carefullyGetValue(selectedMenuItem, ['is_container'], false) ? 'yes' : 'no'}`)} containerStyle={fieldStyle} />
        </>
        :
        <>
          <Autocomplete
            id="icon"
            style={fieldStyle}
            options={iconOptions}
            defaultValue={iconOptions.find(item => item.code === Helper.carefullyGetValue(selectedMenuItem, 'icon.name'.split('.'), ''))} 
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>
                <Icon source={{name: option.code}} style={{marginRight: 10}} />
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => {
              params.InputProps.startAdornment = <InputAdornment position="start">
                    <Icon source={{name: params.inputProps?.value}} />
                  </InputAdornment>;
              return (<TextField
                {...params}
                label={t('field.menu.icon')}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}}
          />
          <DropDownList id="parent_menu" 
            variant="outlined"  
            style={fieldStyle}
            labelText={t('field.menu.parent_menu')} 
            defaultValue={Helper.carefullyGetValue(selectedMenuItem, ['parent_menu'], 'none')}
            options={parentItems}
            error={menuValidation.parent_menu.error}
            helperText={menuValidation.parent_menu.message}
          />
          
          <FormControlLabel
            style={fieldStyle}
            control={
              <Switch 
                id={'switch_is_container'}
                inputProps={{id: 'is_container'}}
                color="primary"
                defaultChecked={Helper.carefullyGetValue(selectedMenuItem, ['is_container'], false)}
                // error={loginPersistencySectionValidation.allow_rememberme.error}
                // helperText={loginPersistencySectionValidation.allow_rememberme.message}
              />
            }
            label={t('field.menu.is_container')}
          />
        </>
      }
      </div>
      {
        menuFormMode === 'view' ?
        <FieldValue label={t(`field.menu.route`)} value={Helper.carefullyGetValue(selectedMenuItem, ['route'], '')} containerStyle={fieldStyle} />
        :
        <Autocomplete
          id="route"
          style={fieldStyle}
          options={availRoute}
          defaultValue={routeDefaultValue} 
          autoHighlight
          freeSolo={true}
          getOptionLabel={(option) => option.label}
          onBlur={(event) => Helper.validateFormField(`route`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          renderOption={(option) => (
            <React.Fragment>
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => {
            return (<TextField
              {...params}
              label={t('field.menu.route')}
              variant="outlined"
              error={menuValidation.route.error}
              helperText={menuValidation.route.message}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}}
        />
      }
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        menuFormMode === 'view' ?
        <>
          <FieldValue label={t(`field.menu.param.query`)} value={Helper.carefullyGetValue(selectedMenuItem, 'param.query'.split('.'), '')} containerStyle={fieldStyle} />
          <FieldValue label={t(`field.menu.param.target`)} value={Helper.carefullyGetValue(selectedMenuItem, 'param.target'.split('.'), '')} containerStyle={fieldStyle} />
          <FieldValue label={t(`field.menu.param.specs`)} value={Helper.carefullyGetValue(selectedMenuItem, 'param.specs'.split('.'), '')} containerStyle={fieldStyle} />
        </>
        :
        <>
          <TextField key={`param_query`} id={`param_query`} label={t(`field.menu.param.query`)} 
            style={fieldStyle}
            variant="outlined" 
            defaultValue={Helper.carefullyGetValue(selectedMenuItem, 'param.query'.split('.'), '')} 
            error={menuValidation.param_query.error}
            helperText={menuValidation.param_query.message}
            onBlur={(event) => Helper.validateFormField(`param_query`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />

          <Autocomplete
            id="param_target"
            style={fieldStyle}
            options={sysCodes.filter(item => item.category === 'ui.window.target').map(item => ({code: item.code, label: item.text}))}
            defaultValue={sysCodes.filter(item => item.category === 'ui.window.target').map(item => ({code: item.code, label: item.text})).find(item => item.code === Helper.carefullyGetValue(selectedMenuItem, 'param.target'.split('.'), ''))} 
            autoHighlight
            freeSolo={true}
            getOptionLabel={(option) => option.code}
            onBlur={(event) => Helper.validateFormField(`param_target`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
            renderOption={(option) => (
              <React.Fragment>
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => {
              return (<TextField
                {...params}
                label={t('field.menu.param.target')}
                variant="outlined"
                error={menuValidation.param_target.error}
                helperText={menuValidation.param_target.message}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}}
          />

          <TextField key={`param_specs`} id={`param_specs`} label={t(`field.menu.param.specs`)} 
            style={fieldStyle}
            variant="outlined" 
            defaultValue={Helper.carefullyGetValue(selectedMenuItem, 'param.specs'.split('.'), '')} 
            error={menuValidation.param_specs.error}
            helperText={menuValidation.param_specs.message}
            onBlur={(event) => Helper.validateFormField(`param_specs`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />
        </>
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        menuFormMode === 'view' ?
        <>
          <FieldValue label={t('field.menu.is_public')} value={t(`option.${Helper.carefullyGetValue(selectedMenuItem, ['is_public'], false) ? 'yes' : 'no'}`)} containerStyle={fieldStyle} />
          <fieldset className={commonClasses.componentFieldValue} style={{flex: 2, marginBottom: 15, minWidth: 300}}>
            <legend style={{width: '-webkit-fit-content'}}>{t('field.menu.role')}</legend>
            <FormGroup row style={{paddingLeft: 15}}>
            {
              sysRoles.map(role => (
                <FormControlLabel key={role.role}
                  style={{marginRight: 30}}
                  control={
                    Helper.carefullyGetValue(selectedMenuItem, ['role'], []).filter(item => item.directus_roles_id === role.role).length > 0 ?
                    <Icon source={{name: 'CheckBox'}} />
                    :
                    <Icon source={{name: 'CheckBoxOutlineBlank'}} />
                  }
                  label={UIHelper.getRoleText(role.role, sysRoles, locale)}
                />
              ))  
            }
            </FormGroup>
          </fieldset>
        </>
        :
        <>
          <FormControlLabel
            style={fieldStyle}
            control={
              <Switch 
                id={'switch_is_public'}
                inputProps={{id: 'is_public'}}
                color="primary"
                checked={menuIsPublic}
                onChange={event => setMenuIsPublic(event.target.checked)}
                // error={loginPersistencySectionValidation.allow_rememberme.error}
                // helperText={loginPersistencySectionValidation.allow_rememberme.message}
              />
            }
            label={t('field.menu.is_public')}
          />

          <fieldset className={commonClasses.componentFieldValue} style={{flex: 2, marginBottom: 15, minWidth: 300}}>
            <legend style={{width: '-webkit-fit-content'}}>{t('field.menu.role')}</legend>
            <FormGroup row>
            {
              sysRoles.map(role => (
                <FormControlLabel key={role.role}
                  control={
                    <Checkbox
                      inputProps={{id: 'role'}}
                      value={role.role}
                      defaultChecked={Helper.carefullyGetValue(selectedMenuItem, ['role'], []).filter(item => item.directus_roles_id === role.role).length > 0}
                      disabled={menuIsPublic}
                      // name="checkedB"
                      // color="primary"
                    />
                  }
                  label={UIHelper.getRoleText(role.role, sysRoles, locale)}
                />
              ))  
            }
            </FormGroup>
          </fieldset>
        </>
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        menuFormMode === 'view' ?
        <>
          <FieldValue label={t('field.menu.sort')} value={`${Helper.carefullyGetValue(selectedMenuItem, ['sort'], 0)}`} containerStyle={fieldStyle} />
          <FieldValue label={t('field.menu.status')} value={Helper.isNotNullAndUndefined(selectedStatus) ? selectedStatus.text : ''} containerStyle={fieldStyle} />
        </>
        :
        <>
          <TextField key={`sort`} id={`sort`} label={t(`field.menu.sort`)} 
            style={fieldStyle}
            variant="outlined" 
            type='number'
            defaultValue={Helper.carefullyGetValue(selectedMenuItem, ['sort'], '')} 
            error={menuValidation.sort.error}
            helperText={menuValidation.sort.message}
            onBlur={(event) => Helper.validateFormField(`sort`, event.target.value, menuFields, menuValidation, setMenuValidation, t)}
          />
          
          <DropDownList id="status" 
            variant="outlined"  
            style={fieldStyle}
            labelText={t('field.menu.status')} 
            defaultValue={Helper.carefullyGetValue(selectedMenuItem, ['status'], '')}
            options={sysCodes.filter(item => item.category === 'general.status')}
            error={menuValidation.status.error}
            helperText={menuValidation.status.message}
          />
        </>
      }
      </div>

    </form>
  }

  const menuToolbarActionButtons = () => {
    // let selectedUserRoleLevel = UserHelper.getRoleLevel(selectedUser?.role, sysRoles);
    return(
      isMobile ?
      <div className={commonClasses.flexFullRow} style={{gap: 10, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end'}}>
        {
          menuFormMode === 'view' ?
          <>
            <div className={commonClasses.flexRow} style={{flex: 2, gap: 10, justifyContent: 'flex-end'}}>
              <IconButton className={commonClasses.toolbarActionSecondaryButtonMobile} onClick={_ => setMenuFormMode('edit')}>
                <MUIIcons.EditRounded />
              </IconButton>
              <IconButton className={commonClasses.toolbarActionWarningButtonMobile} onClick={_ => setShowMenuDelete(true)}>
                <MUIIcons.Delete />
              </IconButton>
              <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={closeMenuItem}>
                <MUIIcons.Close />
              </IconButton>
            </div>
          </>
          :
          <>
            <IconButton className={commonClasses.toolbarActionPrimaryButtonMobile} onClick={_ => updateMenu() }>
              <MUIIcons.Save />
            </IconButton>
            <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={_ => Helper.stringHasValue(selectedMenuItem?.id) ? setMenuFormMode('view') : closeMenuItem()}>
              <MUIIcons.Cancel />
            </IconButton>
          </>
        }
      </div>
      :
      <div className={commonClasses.flexRow} style={{gap: 10, marginRight: 10, alignItems: 'center'}}>
        {
          menuFormMode === 'view' ?
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t('menu.tooltip.view').replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            <Button variant='outlined' color='secondary' startIcon={<MUIIcons.EditRounded />} onClick={_ => setMenuFormMode('edit')}>
              {t('button.edit')}
            </Button>
            <Button variant='outlined' className={commonClasses.deleteOutlinedButton} startIcon={<MUIIcons.Delete />} onClick={_ => setShowMenuDelete(true)}>
              {t('button.delete')}
            </Button>
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Close />} onClick={closeMenuItem}>
              {t('button.close')}
            </Button>
          </>
          :
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t('menu.tooltip.edit').replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            <Button variant='outlined' color='primary' startIcon={<MUIIcons.Save />} onClick={_ => updateMenu()}>
              {t('button.save')}
            </Button>
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Cancel />} onClick={_ => Helper.stringHasValue(selectedMenuItem?.id) ? setMenuFormMode('view') : closeMenuItem()}>
              {t('button.cancel')}
            </Button>
          </>
        }
      </div>
    );
  }

  const updateMenu = () => {
    setMenuUpdateStatus(0);
    try {
      let valid = Helper.validateFormRef(menuFormRef, menuFields, menuValidation, setMenuValidation, t);
      // console.log('Menu Validator: ', menuValidation);

      if(valid) {
        setBusy(true);

        let formData = Helper.deepCopy(selectedMenuItem);

        // Update the formData var with the actual data in the from
        menuFields.forEach(item => {
          if(Helper.isNotNullAndUndefined(menuFormRef.current.elements[item.field])) {
            formData[item.field] = Helper.carefullyGetValue(menuFormRef, `current.elements.${item.field}.value`.split('.'), '');
          }
        });

        formData.is_container = menuFormRef.current.elements.is_container.checked;
        formData.is_public = menuFormRef.current.elements.is_public.checked;
        formData.role = [];
        menuFormRef.current.elements.role.forEach(role => {
          if(role.checked) {
            formData.role.push(role.value);
          } 
        });

        if(Helper.stringHasValue(formData.date_effective)) {
          formData.date_effective = new Date(formData.date_effective);
        }
        else {
          formData.date_effective = null;
        }

        if(Helper.stringHasValue(formData.date_expired)) {
          formData.date_expired = new Date(formData.date_expired);
        }
        else {
          formData.date_expired = null;
        }

        // Populate the menu param
        formData.param = null;
        if(Helper.stringHasValue(formData.param_query)) {
          formData.param = {query: formData.param_query}
        }
        delete formData.param_query;

        if(Helper.stringHasValue(formData.param_target)) {
          if(Helper.isNotNullAndUndefined(formData.param))
            formData.param.target = formData.param_target;
          else
            formData.param = {target: formData.param_target}
        }
        delete formData.param_target;

        if(Helper.stringHasValue(formData.param_specs)) {
          if(Helper.isNotNullAndUndefined(formData.param))
            formData.param.specs = formData.param_specs;
          else
            formData.param = {specs: formData.param_specs}
        }
        delete formData.param_specs;

        if(!Helper.stringHasValue(formData.parent_menu) || formData.parent_menu === 'none') {
          formData.parent_menu = null;
        }
        
        // Set the app id
        formData.application = process.env.APP_ID;

        // setBusy(false);
        // console.log('form elements: ', menuFormRef.current.elements);
        // console.log('form role: ', menuFormRef.current.elements.role);
        // console.log('selectedMenuItem: ', selectedMenuItem);
        console.log('formData: ', formData);

        props.dispatch(
          systemActions.settingMenuSet(formData, (success, result) => {
            setBusy(false);

            if(success) {
              // Set the code update status and refresh the grid view
              setMenuUpdateStatus(1);
              closeMenuItem();
            }
            else {
              // set the code update status as error
              setMenuUpdateStatus(2);
              if(logEnabled) {
                result.message += ' - systemActions.settingMenuSet Error @ /system/setting.js'
                
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('systemActions.settingMenuSet Error @ /system/setting.js: ', result);
            }
          })
        );
      }
    } catch (error) {
      setBusy(false);
      setCodeUpdateStatus(2);
      if(logEnabled) {
        error.message += ' - updateMenu Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('updateMenu Error: ', error);
    }  
  };

  const deleteMenu = () => {
    setMenuDeleteStatus(0);
    try {
      setBusy(true);

      if(Helper.stringHasValue(selectedMenuItem.id)) {
        props.dispatch(
          systemActions.settingMenuDelete(selectedMenuItem.id, (success, result) => {
            setBusy(false);
            setShowMenuDelete(false);

            if(success) {
              // Set the menu delete status
              setMenuDeleteStatus(1);
              closeMenuItem();
            }
            else {
              // set the code update status as error
              setMenuDeleteStatus(2);
              if(logEnabled) {
                result.message += ' - systemActions.settingMenuDelete Error @ /system/setting.js'
                
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('systemActions.settingMenuDelete Error @ /system/setting.js: ', result);
            }
          })
        );
      }
    } catch (error) {
      setBusy(false);
      setMenuDeleteStatus(2);
      if(logEnabled) {
        error.message += ' - deleteMenu Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('deleteMenu Error: ', error);
    }  
  };
  //#endregion

  //#region Screen Manage Methods
  const populateScreen = () => {
    return <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap)}>
      <ReactDataGrid
        idProperty="id"
        onReady={ref => {
          setGridScreenRef(ref);
          ref?.current && ref?.current.focus();
        }}
        theme={dataGridTheme}
        style={gridStyle}
        showActiveRowIndicator={true}
        defaultActiveIndex={0}
        activateRowOnFocus={true}
        enableKeyboardNavigation={true}
        columns={screenColumns}
        dataSource={screenDataSource}
        defaultFilterValue={filterScreenValue}
        onFilterValueChange={setFilterScreenValue}
        pagination
        i18n={UIHelper.reactDataGridI18n(t)}
        showColumnMenuGroupOptions={false}
        showColumnMenuLockOptions={false}
        enableColumnAutosize={false}
        defaultLimit={isMobile ? 10 : 20}
        onRowClick={(rowProps, event) => openSelectedScreen(rowProps.data)}
        onKeyDown={onGridScreenKeyDown}
      />
    </div>
  }

  const populateScreenForm = () => {
    const fieldStyle = {flex: 1, marginBottom: 15, minWidth: 250};
    if(!Helper.isNotNullAndUndefined(selectedScreen))
      return null;

    const selectedStatus = sysCodes.filter(item => item.category === 'general.status').find(item => item.code === Helper.carefullyGetValue(selectedScreen, ['status'], ''));

    return <form ref={screenFormRef} 
      onSubmit={(event) => {
        updateScreen();
        event.preventDefault();
      }}
      className={commonClasses.flexColumn}
      style={{flex: 1}}
    >
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
        siteInfo.supportedLanguages.map(lang => {
          return screenFormMode === 'view' ? 
          <FieldValue key={`title_${lang.replace('-', '_')}`} label={t(`field.screen.title_${lang.replace('-', '_')}`)} value={UIHelper.getObjFieldLangText(selectedScreen, 'title', lang)} containerStyle={fieldStyle} />
          :
          <TextField key={`title_${lang.replace('-', '_')}`} id={`title_${lang.replace('-', '_')}`} label={t(`field.screen.title_${lang.replace('-', '_')}`)} 
            style={fieldStyle}
            variant="outlined" 
            required
            defaultValue={UIHelper.getObjFieldLangText(selectedScreen, 'title', lang)} 
            error={screenValidation[`title_${lang.replace('-', '_')}`].error}
            helperText={screenValidation[`title_${lang.replace('-', '_')}`].message}
            onBlur={(event) => Helper.validateFormField(`title_${lang.replace('-', '_')}`, event.target.value, screenFields, screenValidation, setScreenValidation, t)}
          />
        })
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
        siteInfo.supportedLanguages.map(lang => {
          return screenFormMode === 'view' ?
          <FieldValue key={`description_${lang.replace('-', '_')}`} label={t(`field.screen.description_${lang.replace('-', '_')}`)} value={UIHelper.getObjFieldLangText(selectedScreen, 'description', lang)} containerStyle={fieldStyle} />
          :
          <TextField key={`description_${lang.replace('-', '_')}`} id={`description_${lang.replace('-', '_')}`} label={t(`field.screen.description_${lang.replace('-', '_')}`)} 
            style={fieldStyle}
            variant="outlined" 
            multiline
            maxRows={3}
            minRows={3}
            defaultValue={UIHelper.getObjFieldLangText(selectedScreen, 'description', lang)} 
            error={screenValidation[`description_${lang.replace('-', '_')}`].error}
            helperText={screenValidation[`description_${lang.replace('-', '_')}`].message}
            onBlur={(event) => Helper.validateFormField(`description_${lang.replace('-', '_')}`, event.target.value, screenFields, screenValidation, setScreenValidation, t)}
          />
        })
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        screenFormMode === 'view' ?
        <>
          <FieldValue label={t(`field.screen.date_effective`)} value={UIHelper.formatDate(Helper.carefullyGetValue(selectedScreen, ['date_effective'], null), locale)} containerStyle={fieldStyle} />
          <FieldValue label={t(`field.screen.date_expired`)} value={UIHelper.formatDate(Helper.carefullyGetValue(selectedScreen, ['date_expired'], null), locale)} containerStyle={fieldStyle} />
        </>
        :
        <>
          <TextField key={`date_effective`} id={`date_effective`} label={t(`field.screen.date_effective`)} 
            style={fieldStyle}
            variant="outlined" 
            type='datetime-local'
            placeholder=""
            defaultValue={UIHelper.formatDate(Helper.carefullyGetValue(selectedScreen, ['date_effective'], null), locale, 'yyyy-MM-DDTHH:mm')} 
            error={screenValidation.date_effective.error}
            helperText={screenValidation.date_effective.message}
            onBlur={(event) => Helper.validateFormField(`date_effective`, event.target.value, screenFields, screenValidation, setScreenValidation, t)}
          />
          <TextField key={`date_expired`} id={`date_expired`} label={t(`field.screen.date_expired`)} 
            style={fieldStyle}
            variant="outlined" 
            type='datetime-local'
            defaultValue={UIHelper.formatDate(Helper.carefullyGetValue(selectedScreen, ['date_expired'], null), locale, 'yyyy-MM-DDTHH:mm')} 
            error={screenValidation.date_expired.error}
            helperText={screenValidation.date_expired.message}
            onBlur={(event) => Helper.validateFormField(`date_expired`, event.target.value, screenFields, screenValidation, setScreenValidation, t)}
          />
        </>
      }
      </div>
      {
        screenFormMode === 'view' ?
        <FieldValue label={t(`field.screen.route`)} value={Helper.carefullyGetValue(selectedScreen, ['route'], '')} containerStyle={fieldStyle} />
        :
        <TextField id={`route`} label={t(`field.screen.route`)} 
          style={fieldStyle}
          variant="outlined" 
          defaultValue={Helper.carefullyGetValue(selectedScreen, ['route'], '')} 
          error={screenValidation.route.error}
          helperText={screenValidation.route.message}
          onBlur={(event) => Helper.validateFormField(`route`, event.target.value, screenFields, screenValidation, setScreenValidation, t)}
        />
      }
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        screenFormMode === 'view' ?
        <>
          <FieldValue label={t('field.screen.is_public')} value={t(`option.${Helper.carefullyGetValue(selectedScreen, ['is_public'], false) ? 'yes' : 'no'}`)} containerStyle={fieldStyle} />
          <fieldset className={commonClasses.componentFieldValue} style={{flex: 2, marginBottom: 15, minWidth: 300}}>
            <legend style={{width: '-webkit-fit-content'}}>{t('field.screen.role')}</legend>
            <FormGroup row style={{paddingLeft: 15}}>
            {
              sysRoles.map(role => (
                <FormControlLabel key={role.role}
                  style={{marginRight: 30}}
                  control={
                    Helper.carefullyGetValue(selectedScreen, ['role'], []).filter(item => item.directus_roles_id === role.role).length > 0 ?
                    <Icon source={{name: 'CheckBox'}} />
                    :
                    <Icon source={{name: 'CheckBoxOutlineBlank'}} />
                  }
                  label={UIHelper.getRoleText(role.role, sysRoles, locale)}
                />
              ))  
            }
            </FormGroup>
          </fieldset>
        </>
        :
        <>
          <FormControlLabel
            style={fieldStyle}
            control={
              <Switch 
                id={'switch_is_public'}
                inputProps={{id: 'is_public'}}
                color="primary"
                checked={screenIsPublic}
                onChange={event => setScreenIsPublic(event.target.checked)}
                // error={loginPersistencySectionValidation.allow_rememberme.error}
                // helperText={loginPersistencySectionValidation.allow_rememberme.message}
              />
            }
            label={t('field.screen.is_public')}
          />

          <fieldset className={commonClasses.componentFieldValue} style={{flex: 2, marginBottom: 15, minWidth: 300}}>
            <legend style={{width: '-webkit-fit-content'}}>{t('field.screen.role')}</legend>
            <FormGroup row>
            {
              sysRoles.map(role => (
                <FormControlLabel key={role.role}
                  control={
                    <Checkbox
                      inputProps={{id: 'role'}}
                      value={role.role}
                      defaultChecked={Helper.carefullyGetValue(selectedScreen, ['role'], []).filter(item => item.directus_roles_id === role.role).length > 0}
                      disabled={screenIsPublic}
                      // name="checkedB"
                      // color="primary"
                    />
                  }
                  label={UIHelper.getRoleText(role.role, sysRoles, locale)}
                />
              ))  
            }
            </FormGroup>
          </fieldset>
        </>
      }
      </div>
      <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)}>
      {
        screenFormMode === 'view' ?
        <>
          <FieldValue label={t('field.screen.sort')} value={`${Helper.carefullyGetValue(selectedScreen, ['sort'], 0)}`} containerStyle={fieldStyle} />
          <FieldValue label={t('field.screen.status')} value={Helper.isNotNullAndUndefined(selectedStatus) ? selectedStatus.text : ''} containerStyle={fieldStyle} />
        </>
        :
        <>
          <TextField key={`sort`} id={`sort`} label={t(`field.screen.sort`)} 
            style={fieldStyle}
            variant="outlined" 
            type='number'
            defaultValue={Helper.carefullyGetValue(selectedScreen, ['sort'], '')} 
            error={screenValidation.sort.error}
            helperText={screenValidation.sort.message}
            onBlur={(event) => Helper.validateFormField(`sort`, event.target.value, screenFields, screenValidation, setScreenValidation, t)}
          />
          
          <DropDownList id="status" 
            variant="outlined"  
            style={fieldStyle}
            labelText={t('field.screen.status')} 
            defaultValue={Helper.carefullyGetValue(selectedScreen, ['status'], '')}
            options={sysCodes.filter(item => item.category === 'general.status')}
            error={screenValidation.status.error}
            helperText={screenValidation.status.message}
          />
        </>
      }
      </div>

    </form>
  }

  const openSelectedScreen = (data, formMode='view') => {
    console.log('openSelectedScreen selectedData: ', data);
    setHideFab(true);
    setScreenIsPublic(data.is_public);
    setScreenFormMode(formMode);
    setSelectedScreen(data);
  }

  const closeSelectedScreen = () => {
    setSelectedScreen(null);
    setScreenFormMode('view');
    setHideFab(false);
    setScreenIsPublic(false);
    setScreenValidation(screenValidationInitState);
  }

  const onGridScreenKeyDown = React.useCallback(event => {
    if(event.key === 'Enter' && gridScreenRef?.current && gridScreenRef.current.activeRowRef 
       && gridScreenRef.current.activeRowRef.current && gridScreenRef.current.activeRowRef.current.instance 
       && gridScreenRef.current.activeRowRef.current.instance.props
       && gridScreenRef.current.activeRowRef.current.instance.props.data) {
      openSelectedScreen(gridScreenRef.current.activeRowRef.current.instance.props.data);
    }
  }, [gridScreenRef]);

  const screenToolbarActionButtons = () => {
    // let selectedUserRoleLevel = UserHelper.getRoleLevel(selectedUser?.role, sysRoles);
    return(
      isMobile ?
      <div className={commonClasses.flexFullRow} style={{gap: 10, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end'}}>
        {
          screenFormMode === 'view' ?
          <>
            <div className={commonClasses.flexRow} style={{flex: 2, gap: 10, justifyContent: 'flex-end'}}>
              <IconButton className={commonClasses.toolbarActionSecondaryButtonMobile} onClick={_ => setScreenFormMode('edit')}>
                <MUIIcons.EditRounded />
              </IconButton>
              <IconButton className={commonClasses.toolbarActionWarningButtonMobile} onClick={_ => setShowScreenDelete(true)}>
                <MUIIcons.Delete />
              </IconButton>
              <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={closeSelectedScreen}>
                <MUIIcons.Close />
              </IconButton>
            </div>
          </>
          :
          <>
            <IconButton className={commonClasses.toolbarActionPrimaryButtonMobile} onClick={updateScreen}>
              <MUIIcons.Save />
            </IconButton>
            <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={_ => Helper.stringHasValue(selectedScreen?.id) ? setScreenFormMode('view') : closeSelectedScreen()}>
              <MUIIcons.Cancel />
            </IconButton>
          </>
        }
      </div>
      :
      <div className={commonClasses.flexRow} style={{gap: 10, marginRight: 10, alignItems: 'center'}}>
        {
          screenFormMode === 'view' ?
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t('screen.tooltip.view').replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            <Button variant='outlined' color='secondary' startIcon={<MUIIcons.EditRounded />} onClick={_ => setScreenFormMode('edit')}>
              {t('button.edit')}
            </Button>
            <Button variant='outlined' className={commonClasses.deleteOutlinedButton} startIcon={<MUIIcons.Delete />} onClick={_ => setShowScreenDelete(true)}>
              {t('button.delete')}
            </Button>
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Close />} onClick={closeSelectedScreen}>
              {t('button.close')}
            </Button>
          </>
          :
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t('screen.tooltip.edit').replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            <Button variant='outlined' color='primary' startIcon={<MUIIcons.Save />} onClick={updateScreen}>
              {t('button.save')}
            </Button>
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Cancel />} onClick={_ => Helper.stringHasValue(selectedScreen?.id) ? setScreenFormMode('view') : closeSelectedScreen()}>
              {t('button.cancel')}
            </Button>
          </>
        }
      </div>
    );
  }

  const newScreen = () => {
    const screen = {
      id: "",
      name: "",
      title: "",
      description: "",
      route: "",
      is_public: false,
      date_effective: null,
      date_expired: null,
      sort: 0,
      status: "draft",
      role: [],
      translations: null
    }
    openSelectedScreen(screen, 'edit');
  };

  const updateScreen = () => {
    setScreenUpdateStatus(0);
    try {
      let valid = Helper.validateFormRef(screenFormRef, screenFields, screenValidation, setScreenValidation, t);
      console.log('Screen Validator: ', screenValidation);

      if(valid) {
        setBusy(true);

        let formData = Helper.deepCopy(selectedScreen);

        // Update the formData var with the actual data in the from
        screenFields.forEach(item => {
          if(Helper.isNotNullAndUndefined(screenFormRef.current.elements[item.field])) {
            formData[item.field] = Helper.carefullyGetValue(screenFormRef, `current.elements.${item.field}.value`.split('.'), '');
          }
        });

        formData.name = formData.title_en_US;
        formData.is_public = screenFormRef.current.elements.is_public.checked;
        formData.role = [];
        screenFormRef.current.elements.role.forEach(role => {
          if(role.checked) {
            formData.role.push(role.value);
          } 
        });

        if(Helper.stringHasValue(formData.date_effective)) {
          formData.date_effective = new Date(formData.date_effective);
        }
        else {
          formData.date_effective = null;
        }

        if(Helper.stringHasValue(formData.date_expired)) {
          formData.date_expired = new Date(formData.date_expired);
        }
        else {
          formData.date_expired = null;
        }

        // Set the app id
        formData.application = process.env.APP_ID;

        // setBusy(false);
        // console.log('form elements: ', menuFormRef.current.elements);
        // console.log('form role: ', menuFormRef.current.elements.role);
        // console.log('selectedScreen: ', selectedScreen);
        console.log('formData: ', formData);

        props.dispatch(
          systemActions.settingScreenSet(formData, (success, result) => {
            setBusy(false);

            if(success) {
              // Set the code update status and refresh the grid view
              setScreenUpdateStatus(1);
              closeSelectedScreen();
              gridScreenRef.current?.reload();
            }
            else {
              // set the code update status as error
              setScreenUpdateStatus(2);
              if(logEnabled) {
                result.message += ' - systemActions.settingScreenSet Error @ /system/setting.js'
                
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('systemActions.settingScreenSet Error @ /system/setting.js: ', result);
            }
          })
        );
      }
    } catch (error) {
      setBusy(false);
      setScreenUpdateStatus(2);
      if(logEnabled) {
        error.message += ' - updateScreen Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('updateScreen Error: ', error);
    }  
  };

  const deleteScreen = () => {
    setScreenDeleteStatus(0);
    try {
      setBusy(true);

      props.dispatch(
        systemActions.settingScreenDelete(selectedScreen.id, (success, result) => {
          setBusy(false);
          setShowScreenDelete(false);

          if(success) {
            // Set the code delete status and refresh the grid view
            setScreenDeleteStatus(1);
            closeSelectedScreen();
            gridScreenRef.current?.reload();
          }
          else {
            // set the code delete status as error
            setScreenDeleteStatus(2);
            if(logEnabled) {
              result.message += ' - systemActions.settingScreenDelete Error @ /system/setting.js'
              
              props.dispatch(
                loggingActions.loggingNew(
                  Helper.populateLoggingDetail(result, {
                    session: props.session.toJS()
                  })
                )
              );
            }
            consoleLogEnabled && console.error('systemActions.settingScreenDelete Error @ /system/setting.js: ', result);
          }
        })
      );
      
    } catch (error) {
      setBusy(false);
      setScreenDeleteStatus(2);
      setShowScreenDelete(false);
      if(logEnabled) {
        error.message += ' - deleteScreen Error @ /system/setting.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('deleteCode Error: ', error);
    }  
  };
  //#endregion

  const fabOnClick = (event) => {
    switch (selectedTab) {
      case 2:
        let newCode = {id: '', category: '', code: '', text:'', sort: 0, status: 'draft', translations: []};
        if(Helper.arrayHasItem(siteInfo?.supportedLanguages)) {
          siteInfo.supportedLanguages.filter(item => item !== 'en-US').forEach(element => {
            newCode.translations.push({
              id: 0,
              languages_code: element,
              text: ''
            });
          });
        }
        openSelectedCode(newCode);
        
        break;
    
      case 4:
        newMenuItem();
        break;

      case 5:
        newScreen();
        break;
      default:
        break;
    }
  }

  const helpScreenGetText = (type) => {
    let text ='';
    let key = '';

    switch (selectedTab) {
      case 0:
        key = type === 'functions' ? 'dialog.help.functions' : 'dialog.help.keys';
        break;

      case 1:
        key = type === 'functions' ? 'dialog.help.generalsetting.functions' : 'dialog.help.generalsetting.keys';
        break;

      case 2:
        key = type === 'functions' ? 'dialog.help.code.functions' : 'dialog.help.code.keys';
        break;

      case 3:
        key = type === 'functions' ? 'dialog.help.role.functions' : 'dialog.help.role.keys';
        break;

      case 4:
        key = type === 'functions' ? 'dialog.help.menu.functions' : 'dialog.help.menu.keys';
        break;

      case 5:
        key = type === 'functions' ? 'dialog.help.screen.functions' : 'dialog.help.screen.keys';
        break;
    
      default:
        break;
    }

    if(Helper.stringHasValue(key)) {
      text = t(key).replace(/{modifier}/g, UIHelper.getModifierKey());
    }
    
    return text;
  }

  React.useEffect(() => {
    const deleteRecord = () => {
      switch (selectedTab) {
        case 2:
          if(Helper.isNotNullAndUndefined(selectedCode)) {
            setShowCodeDelete(true);
          }
          break;
      
        case 4:
          if(Helper.isNotNullAndUndefined(selectedMenuItem)) {
            setShowMenuDelete(true);
          }
          break;
  
        case 5:
          if(Helper.isNotNullAndUndefined(selectedScreen)) {
            setShowScreenDelete(true);
          }
          break;
        default:
          break;
      }
    }

    const escPressed = (event) => {
      switch (selectedTab) {
        case 1:
          if(loginPersistencySection.mode !== 'view' && !loginPersistencySection.busy) {
            setLoginPersistencySection({mode: 'view', busy: false});
          }
          
          if(loginSecuritySection.mode !== 'view' && !loginSecuritySection.busy) {
            setLoginSecuritySection({mode: 'view', busy: false});
          }

          if(systemUserMgtSection.mode !== 'view' && !systemUserMgtSection.busy) {
            setSystemUserMgtSection({mode: 'view', busy: false});
          }

          if(systemLoggingSection.mode !== 'view' && !systemLoggingSection.busy) {
            setSystemLoggingSection({mode: 'view', busy: false});
          }
          
          event.preventDefault();
          break;
      
        case 3:
          onRoleSelect('')(null, false);
          event.preventDefault();
          break;

        case 4:
          if(menuFormMode === 'edit') {
            if(Helper.stringHasValue(selectedMenuItem?.id))
              setMenuFormMode('view');
            else
              closeMenuItem();
          }

          if(menuFormMode === 'view') {
            closeMenuItem();
          }
          event.preventDefault();
          break;

        case 5:
          if(screenFormMode === 'edit') {
            if(Helper.stringHasValue(selectedScreen?.id))
              setScreenFormMode('view');
            else
              closeSelectedScreen();
          }

          if(screenFormMode === 'view') {
            closeSelectedScreen();
          }
          event.preventDefault();
          break;

        default:
          break;
      }
    }

    const editRecord = (event) => {
      switch (selectedTab) {
        case 1:
          if(loginPersistencySection.mode === 'view' && !loginPersistencySection.busy) {
            setLoginPersistencySection({mode: 'edit', busy: false});
          }
          
          if(loginSecuritySection.mode === 'view' && !loginSecuritySection.busy) {
            setLoginSecuritySection({mode: 'edit', busy: false});
          }

          if(systemUserMgtSection.mode === 'view' && !systemUserMgtSection.busy) {
            setSystemUserMgtSection({mode: 'edit', busy: false});
          }

          if(systemLoggingSection.mode === 'view' && !systemLoggingSection.busy) {
            setSystemLoggingSection({mode: 'edit', busy: false});
          }
          event.preventDefault();
          break;
      
        case 4:
          if(Helper.isNotNullAndUndefined(selectedMenuItem) && menuFormMode === 'view') {
            setMenuFormMode('edit');
            event.preventDefault();
          }
          break;

        case 5:
          if(Helper.isNotNullAndUndefined(selectedScreen) && screenFormMode === 'view') {
            setScreenFormMode('edit');
            event.preventDefault();
          }
          break;
        default:
          break;
      }
      
    }

    const updateRecord = (event) => {
      switch (selectedTab) {
        case 1:
          if(Helper.arrayHasItem(sysConfig)) {
            sysConfig.forEach(config => {
              switch (config.identifier.toLowerCase()) {
                case 'login.persistency':
                  if(loginPersistencySection.mode === 'edit' && !loginPersistencySection.busy) {
                    updateLoginPersistency(config);
                  }
                  break;
              
                case 'login.security':
                  if(loginSecuritySection.mode === 'edit' && !loginSecuritySection.busy) {
                    updateLoginSecurity(config);
                  }
                  break;

                case 'system.usermgt':
                  if(systemUserMgtSection.mode === 'edit' && !systemUserMgtSection.busy) {
                    updateUserManagement(config);
                  }
                  break;

                case 'system.logging':
                  if(systemLoggingSection.mode === 'edit' && !systemLoggingSection.busy) {
                    updateSystemLogging(config);
                  }
                  break;

                default:
                  break;
              }
            });
          }
          event.preventDefault();
          break;
      
        case 2:
          if(Helper.isNotNullAndUndefined(selectedCode)) {
            updateCode();
            event.preventDefault();
          }
          break;

        case 3:
          if(Helper.stringHasValue(selectedRoleSettingId)) {
            updateRole();
            event.preventDefault();
          }
          break;

        case 4:
          if(Helper.isNotNullAndUndefined(selectedMenuItem) && menuFormMode === 'edit') {
            updateMenu();
            event.preventDefault();
          }
          break;

        case 5:
          if(Helper.isNotNullAndUndefined(selectedScreen) && screenFormMode === 'edit') {
            updateScreen();
            event.preventDefault();
          }
          break;

        default:
          break;
      }
    }

    const onKeyDown = (event) => {
      // console.log('onKeyDown: ', event);
      let key = Helper.carefullyGetValue(event, ['key'], '');
      let hasModifierKey = osName === 'Mac OS' ? event.metaKey : event.ctrlKey;
      switch (key.toLowerCase()) {
        case 'f1':
          // Show context help screen
          setShowHelp(value => !value);
          event.preventDefault();
          break;

        case '1': // Ctrl+1 to 6 for Windows, Cmd+1 to 6 for Mac, to switch tab
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          if(!showHelp && hasModifierKey && !showCodeDelete && !Helper.isNotNullAndUndefined(selectedCode) &&
             !Helper.isNotNullAndUndefined(selectedMenuItem) && !Helper.isNotNullAndUndefined(selectedScreen)) {
            setSelectedTab(Number.parseInt(key) - 1);
            event.preventDefault();
          }
          break;

        case 'arrowleft':
        case 'arrowright':
          if(!showHelp && hasModifierKey && !showCodeDelete && !Helper.isNotNullAndUndefined(selectedCode) &&
              !Helper.isNotNullAndUndefined(selectedMenuItem) && !Helper.isNotNullAndUndefined(selectedScreen)) {
            setSelectedTab(value => {
              let newTabIdx = value;
              if(key.toLowerCase() === 'arrowleft')
                newTabIdx--;
              else
                newTabIdx++;

              // Make sure the newTabIdx is not out of range
              if(newTabIdx < 0)
                newTabIdx = 0;

              if(newTabIdx > 5)
                newTabIdx = 5;

              return newTabIdx;
            });
            event.preventDefault();
          }
          break;

        case 'g': // Ctrl+G for Windows, Cmd+G for Mac, to make data grid in focus
          if(!showHelp && selectedTab === 2 && !Helper.isNotNullAndUndefined(selectedCode)) {
            gridCodeRef?.current && gridCodeRef.current.focus();
            event.preventDefault();
          }

          if(!showHelp && selectedTab === 5 && !Helper.isNotNullAndUndefined(selectedScreen)) {
            gridScreenRef?.current && gridScreenRef.current.focus();
            event.preventDefault();
          }
          break;

        case 'escape': // Esc key to cancel edit
          if(!showHelp) {
            escPressed(event);
          }
          break;

        case 'c': // Ctrl+C for Windows, Cmd+C for Mac, to create new user
          if(!showHelp && hasModifierKey) {
            // Show new record form
            fabOnClick(event);
          }
          break;

        case 'e': // Ctrl+E for Windows, Cmd+E for Mac, to enter edit mode
          if(!showHelp && hasModifierKey) {
            editRecord(event);
          }
          break;

        case 's': // Ctrl+S for Windows, Cmd+S for Mac, to save the changes
          if(!showHelp && hasModifierKey) {
            updateRecord(event);
          }
          break;

        case 'd': // Ctrl+D for Windows, Cmd+D for Mac, to delete record
          if(!showHelp && hasModifierKey) {
            deleteRecord();
            event.preventDefault();
          }
          break;

        case 'delete': // Delete key, to delete user
          if(!showHelp) {
            deleteRecord();
            event.preventDefault();
          }
          break;

        default:
          break;
      }
      
    };

    if(isBrowser) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if(isBrowser) {
        document.removeEventListener("keydown", onKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCode, selectedMenuItem, selectedScreen, showHelp, selectedTab,
      loginPersistencySection, loginSecuritySection, systemUserMgtSection, 
      systemLoggingSection, selectedRoleSettingId, menuFormMode, screenFormMode,
      sysConfig]); 

  return(
    <Layout busy={busy}>
      <div className={clsx(commonClasses.flexFullColumn)}>
        <Paper variant={'elevation'} elevation={4}
          className={commonClasses.form}
        >
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={onTabChange}
            aria-label="system setting tabs"
            variant="scrollable"
            scrollButtons="auto"
            style={{backgroundColor: theme.palette.background.default}}
          >
            <Tab label={t('tab.label.info')} />
            <Tab label={t('tab.label.general')} />
            <Tab label={t('tab.label.codes')} />
            <Tab label={t('tab.label.roles')} />
            <Tab label={t('tab.label.menus')} />
            <Tab label={t('tab.label.pages')} />
          </Tabs>
          <Divider />
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={selectedTab}
            onChangeIndex={isMobile ? onChangeTabIndex : undefined}
          >
            {/* App Info Tab */}
            <TabPanel selectedTab={selectedTab} index={0} dir={theme.direction}>
              {populateAppInfo()}
            </TabPanel>
            {/* General Setting Tab */}
            <TabPanel selectedTab={selectedTab} index={1} dir={theme.direction}>
              {populateSetting()}
            </TabPanel>
            {/* Code Setting Tab */}
            <TabPanel selectedTab={selectedTab} index={2} dir={theme.direction}>
              {populateCode()}
            </TabPanel>
            {/* Role Setting Tab */}
            <TabPanel selectedTab={selectedTab} index={3} dir={theme.direction}>
              {populateRole()}
            </TabPanel>
            {/* Menu Setting Tab */}
            <TabPanel selectedTab={selectedTab} index={4} dir={theme.direction}>
              {populateMenu()}
            </TabPanel>
            {/* Page Setting Tab */}
            <TabPanel selectedTab={selectedTab} index={5} dir={theme.direction}>
              {populateScreen()}
            </TabPanel>
           
          </SwipeableViews>
        </Paper>
        <Zoom in={Helper.isNotNullAndUndefined(selectedMenuItem)}>
          <Paper variant={'elevation'} elevation={4}
            className={commonClasses.overlayForm}
          >
            <Toolbar title={Helper.stringHasValue(selectedMenuItem?.id) ?  UIHelper.getObjFieldLangText(selectedMenuItem, 'name', locale) : t('menu.new.title')} 
              className={isMobile ? undefined : commonClasses.toolbarFloatingContainer}
              showIcon={true}
              iconComp={<Icon source={selectedMenuItem?.icon} />}
              showCloseButton={false}
              customCtrl={
                isMobile ?
                null
                :
                <div> 
                  {/* Show action button within Toolbar when not in mobile device */}
                  {menuToolbarActionButtons()}
                </div>
              }
            />
            {
              // Show action buttons seperate from toolbar in mobile device
              isMobile &&
              <div className={commonClasses.toolbarFloatingActionButtonContainer}>
                {menuToolbarActionButtons()}
              </div>
            }
            <div className={clsx(commonClasses.flexColumn, commonClasses.defaultPadding)} style={{marginTop: 6}}>
              {populateMenuForm()}
            </div>
          </Paper>
        </Zoom>
        <Zoom in={Helper.isNotNullAndUndefined(selectedScreen)}>
          <Paper variant={'elevation'} elevation={4}
            className={commonClasses.overlayForm}
          >
            <Toolbar title={Helper.stringHasValue(selectedScreen?.id) ? UIHelper.getObjFieldLangText(selectedScreen, 'title', locale) : t('screen.new.title')} 
              className={isMobile ? undefined : commonClasses.toolbarFloatingContainer}
              showIcon={false}
              // iconComp={<Icon source={selectedMenuItem?.icon} />}
              showCloseButton={false}
              customCtrl={
                isMobile ?
                null
                :
                <div> 
                  {/* Show action button within Toolbar when not in mobile device */}
                  {screenToolbarActionButtons()}
                </div>
              }
            />
            {
              // Show action buttons seperate from toolbar in mobile device
              isMobile &&
              <div className={commonClasses.toolbarFloatingActionButtonContainer}>
                {screenToolbarActionButtons()}
              </div>
            }
            <div className={clsx(commonClasses.flexColumn, commonClasses.defaultPadding)} style={{marginTop: 6}}>
              {populateScreenForm()}
            </div>
          </Paper>
        </Zoom>
      </div>
      {
        [2, 4, 5].includes(selectedTab) && !hideFab &&
        <Fab color="primary" aria-label="add" 
          onClick={fabOnClick}
          className={commonClasses.fab}
        >
          <MUIIcons.Add />
        </Fab>
      }
      {/* General setting Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={generalSettingStatus.show}
        onClose={_ => setGeneralSettingStatus(value => {
          let newValue = Helper.deepCopy(value);
          newValue.show = false;
          return newValue;
        })}
        key={'generalSettingUpdateStatusSnackbar'}
      >
        {
          generalSettingStatus.show ?
          <Alert 
            onClose={_ => setGeneralSettingStatus(value => {
              let newValue = Helper.deepCopy(value);
              newValue.show = false;
              return newValue;
            })}
            severity={generalSettingStatus.type}
          >
            {generalSettingStatus.message}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Code Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={codeUpdateStatus !== 0}
        onClose={_ => setCodeUpdateStatus(0)}
        key={'codeUpdateStatusSnackbar'}
      >
        {
          codeUpdateStatus !== 0 ?
          <Alert onClose={_ => setCodeUpdateStatus(0)} severity={codeUpdateStatus === 1 ? 'success' : 'error'}>
            {t(`dialog.code.updatestatus.${codeUpdateStatus === 1 ? 'success' : 'failed'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Code Delete Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={codeDeleteStatus !== 0}
        onClose={_ => setCodeDeleteStatus(0)}
        key={'codeDeleteStatusSnackbar'}
      >
        {
          codeDeleteStatus !== 0 ?
          <Alert onClose={_ => setCodeDeleteStatus(0)} severity={codeDeleteStatus === 1 ? 'success' : 'error'}>
            {t(`dialog.code.deletestatus.${codeDeleteStatus === 1 ? 'success' : 'failed'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Role Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={roleUpdateStatus !== 0}
        onClose={_ => setRoleUpdateStatus(0)}
        key={'roleUpdateStatusSnackbar'}
      >
        {
          roleUpdateStatus !== 0 ?
          <Alert onClose={_ => setRoleUpdateStatus(0)} severity={roleUpdateStatus === 1 ? 'success' : 'error'}>
            {t(`role.updatestatus.${roleUpdateStatus === 1 ? 'success' : 'failed'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Menu Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={menuUpdateStatus !== 0}
        onClose={_ => setMenuUpdateStatus(0)}
        key={'menuUpdateStatusSnackbar'}
      >
        {
          menuUpdateStatus !== 0 ?
          <Alert onClose={_ => setMenuUpdateStatus(0)} severity={menuUpdateStatus === 1 ? 'success' : 'error'}>
            {t(`menu.updatestatus.${menuUpdateStatus === 1 ? 'success' : 'error'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Menu Delete Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={menuDeleteStatus !== 0}
        onClose={_ => setMenuDeleteStatus(0)}
        key={'menuDeleteStatusSnackbar'}
      >
        {
          menuDeleteStatus !== 0 ?
          <Alert onClose={_ => setMenuDeleteStatus(0)} severity={menuDeleteStatus === 1 ? 'success' : 'error'}>
            {t(`menu.deletestatus.${menuDeleteStatus === 1 ? 'success' : 'error'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Screen Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={screenUpdateStatus !== 0}
        onClose={_ => setScreenUpdateStatus(0)}
        key={'screenUpdateStatusSnackbar'}
      >
        {
          screenUpdateStatus !== 0 ?
          <Alert onClose={_ => setScreenUpdateStatus(0)} severity={screenUpdateStatus === 1 ? 'success' : 'error'}>
            {t(`screen.updatestatus.${screenUpdateStatus === 1 ? 'success' : 'error'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Screen Delete Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={screenDeleteStatus !== 0}
        onClose={_ => setScreenDeleteStatus(0)}
        key={'screenDeleteStatusSnackbar'}
      >
        {
          screenDeleteStatus !== 0 ?
          <Alert onClose={_ => setScreenDeleteStatus(0)} severity={screenDeleteStatus === 1 ? 'success' : 'error'}>
            {t(`screen.deletestatus.${screenDeleteStatus === 1 ? 'success' : 'error'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Code Editor Dialog */}
      <Dialog
        open={Helper.isNotNullAndUndefined(selectedCode)}
        TransitionComponent={Transition}
        keepMounted={false}
        style={{ zIndex: 1000 }}
        onClose={closeSelectedCode}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <form ref={codeFormRef} onSubmit={(event) => {
            updateCode();
            event.preventDefault();
          }}
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <Typography variant="h6">
              {t(`dialog.code.title.${Helper.stringHasValue(selectedCode?.id) ? 'edit' : 'new'}`)}
            </Typography>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={commonClasses.flexColumn}>
            <Typography variant='body1' gutterBottom>
              {t('dialog.code.desc')}
            </Typography>
            <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)} style={{justifyContent: 'space-between'}}>
              <DropDownList id="category" 
                variant="outlined"  
                style={{flex: 1, minWidth: 200, marginBottom: 10}}
                labelText={t('field.code.category')} 
                disabled={Helper.stringHasValue(selectedCode?.id)}
                defaultValue={Helper.carefullyGetValue(selectedCode, ['category'], '')}
                options={codeCategory}
                error={codeValidation.category.error}
                helperText={codeValidation.category.message}
              />
              <TextField id="code" label={t('field.code.code')} 
                style={{flex: 1, minWidth: 200, marginBottom: 10}}
                variant="outlined" 
                disabled={Helper.stringHasValue(selectedCode?.id)}
                defaultValue={Helper.carefullyGetValue(selectedCode, ['code'], '')} 
                error={codeValidation.code.error}
                helperText={codeValidation.code.message}
                onBlur={(event) => Helper.validateFormField('code', event.target.value, codeFields, codeValidation, setCodeValidation, t)}
              />
            </div>
            <div className={clsx(commonClasses.flexFullRow, commonClasses.flexWrap)} style={{justifyContent: 'space-between'}}>
              <TextField id="sort" label={t('field.code.sort')} 
                style={{flex: 1, minWidth: 200, marginBottom: 10}}
                variant="outlined" 
                defaultValue={Helper.carefullyGetValue(selectedCode, ['sort'], '')} 
                error={codeValidation.sort.error}
                helperText={codeValidation.sort.message}
                onBlur={(event) => Helper.validateFormField('sort', event.target.value, codeFields, codeValidation, setCodeValidation, t)}
              />
              <DropDownList id="status" 
                variant="outlined"  
                style={{flex: 1, minWidth: 200, marginBottom: 10}}
                labelText={t('field.code.status')} 
                defaultValue={Helper.carefullyGetValue(selectedCode, ['status'], '')}
                options={sysCodes.filter(item => item.category === 'general.status')}
                error={codeValidation.status.error}
                helperText={codeValidation.status.message}
              />
            </div>
            
            {
              Helper.arrayHasItem(siteInfo?.supportedLanguages) &&
              siteInfo.supportedLanguages.map(item => {
                return <TextField key={`text_${item.replace('-', '_')}`} id={`text_${item.replace('-', '_')}`} label={t(`field.code.text_${item.replace('-', '_')}`)} 
                  style={{marginBottom: 10}}
                  variant="outlined" 
                  defaultValue={Helper.carefullyGetValue(selectedCode, [`text_${item.replace('-', '_')}`], '')} 
                  error={codeValidation[`text_${item.replace('-', '_')}`].error}
                  helperText={codeValidation[`text_${item.replace('-', '_')}`].message}
                  onBlur={(event) => Helper.validateFormField(`text_${item.replace('-', '_')}`, event.target.value, codeFields, codeValidation, setCodeValidation, t)}
                />
              })
            }
          </DialogContent>
          <DialogActions>
            <div className={commonClasses.flexFullRow}>
              <div style={{flex: 1, justifyContent: 'flex-start'}}>
              {
                Helper.stringHasValue(selectedCode?.id) &&
                <Button variant='outlined' className={commonClasses.deleteOutlinedButton} startIcon={<MUIIcons.Delete />} onClick={_ => setShowCodeDelete(true)}>
                  {t('button.delete')}
                </Button>
              }
              </div>
              <div className={commonClasses.flexRow} style={{flex: 2, justifyContent: 'flex-end'}}>
                <Button
                  onClick={closeSelectedCode}
                >
                  {t('button.cancel')}
                </Button>
                <Button variant={'contained'} type='submit' 
                  color={'primary'}
                >
                  {t(`button.${Helper.stringHasValue(selectedCode?.id) ? 'update' : 'create'}`)}
                </Button>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>

      {/* Code Delete Dialog */}
      <Dialog
        open={showCodeDelete}
        TransitionComponent={Transition}
        classes={{
          paper: commonClasses.alert_DialogBoxContainer
        }}
        keepMounted
        style={{ zIndex: 1100 }}
        onClose={_ => setShowCodeDelete(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <Typography variant="h6">
            {t('dialog.code.title.delete')}
          </Typography>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Typography variant='body1'>
            {t('dialog.code.desc.delete')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_ => setShowCodeDelete(false)}
          >
            {t('button.cancel')}
          </Button>
          <Button
            onClick={_ => deleteCode()}
          >
            {t('button.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Menu Delete Dialog */}
      <Dialog
        open={showMenuDelete}
        TransitionComponent={Transition}
        classes={{
          paper: commonClasses.alert_DialogBoxContainer
        }}
        keepMounted
        style={{ zIndex: 1100 }}
        onClose={_ => setShowMenuDelete(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <Typography variant="h6">
            {t('dialog.menu.title.delete')}
          </Typography>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Typography variant='body1'>
            {t('dialog.menu.desc.delete')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_ => setShowMenuDelete(false)}
          >
            {t('button.cancel')}
          </Button>
          <Button
            onClick={_ => deleteMenu()}
          >
            {t('button.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Screen Delete Dialog */}
      <Dialog
        open={showScreenDelete}
        TransitionComponent={Transition}
        classes={{
          paper: commonClasses.alert_DialogBoxContainer
        }}
        keepMounted
        style={{ zIndex: 1100 }}
        onClose={_ => setShowScreenDelete(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <Typography variant="h6">
            {t('dialog.screen.title.delete')}
          </Typography>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Typography variant='body1'>
            {t('dialog.screen.desc.delete')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_ => setShowScreenDelete(false)}
          >
            {t('button.cancel')}
          </Button>
          <Button
            onClick={_ => deleteScreen()}
          >
            {t('button.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Help Screen */}
      <HelpScreen
        open={showHelp}
        onClose={_ => setShowHelp(false)}
        title={t('dialog.help.title')}
      >
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.desc')}
        </Typography>
        <Typography variant='body1' style={{marginBottom: 20}}>
          {helpScreenGetText('functions')}
        </Typography>
        <MuiMarkdown>
          {helpScreenGetText('keys')}
        </MuiMarkdown>
      </HelpScreen>
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(SettingPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
